import classNames from "classnames";
import React from "react";

const LevelTab = ({ state, setState, download, data }) => {
    
    const renderDownloaddownload = (book) => {
        if (!download || !book.bookPath?.length || !download.downloadStatuses[book?.sequence]) return null;

        return (
            <span className="group relative h-6 w-6 ml-2">
                <span 
                    onClick={(e) => {
                        e.stopPropagation();
                        download.onDownload(book);
                    }} 
                    className="material-symbols-outlined custom-download-wrapper cursor-pointer"
                >
                    download
                </span>
                {download.downloadingStates[book.sequence] && 
                    <div className="tooltip-top -top-[38px] block">
                        {download.downloadingText}
                    </div>    
                }
            </span>
        );
    };

    return (
        <div className="mx-auto mt-5">
            <ul id="tabs" className="flex gap-2 sm:gap-5 justify-between mx-auto text-start">
                {data?.map((book, index) => (
                    <li
                        key={index}
                        className="font-bold w-full relative pb-1 cursor-pointer"
                        onClick={() => setState(book.categoryTitle)}
                    >
                        <div className="flex justify-between">
                            <button type="button">{book.categoryTitle}</button>
                            {renderDownloaddownload(book)}
                        </div>
                        <div
                            className={classNames("absolute bottom-0 left-0 right-0 h-[3px] rounded", {
                                "bg-primary": state === book.categoryTitle,
                                "bg-base-text": state !== book.categoryTitle
                            })}
                        >
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default LevelTab;