import { useEffect, useRef, useState } from 'react';
import { Zoom_In } from "../../../../../../assets/svg";
import { BookModal } from '../../../../../../components/Modals';
import useAnalytics from '../../../../../../hooks/useAnalytics';
import { useLocalization } from '../../../../../../hooks/useLocalization';

const LessonBook = ({ data, convertedData, events, analyticsPass = false }) => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const params = new URLSearchParams(window.location.search);

    const openBook = params.get('openBook') || 'false';

    const modalRef = useRef();

    const [currentTab, setCurrentTab] = useState(3);
    const [timeoutState, setTimeoutState] = useState(true);

    const bookLocalization = [
        strings.speaking_lesson.daily_lesson.book,
        strings.speaking_lesson.daily_lesson.presentation,
        strings.speaking_lesson.daily_lesson.practice
    ]

    const toggleModal = () => {
        !analyticsPass && analytics.useAnalytics(events[0]);
        modalRef.current.classList.toggle("hidden");

        if(!modalRef.current.classList.contains("hidden")){
            setTimeout(() => setTimeoutState(false), 4000);
        } else {
            setTimeoutState(timeoutState => true);
        }
    }

    useEffect(() => {
        const isComeFromDashboard = localStorage.getItem("isLessonBookClicked");
        
        if(isComeFromDashboard){
            toggleModal();
            localStorage.removeItem("isLessonBookClicked");
        }
    }, [])

    useEffect(() => {
        const localCloseAutoBookItem = localStorage.getItem('closeAutoBook') || false;

        if (openBook === 'true' && !localCloseAutoBookItem) {
            toggleModal();

            localStorage.setItem('closeAutoBook', JSON.stringify("true"));
        }
    }, [openBook]);

    return (
        <>

            <div className="transparent-section mt-5">
                <h5 className="text-secondary">{strings.speaking_lesson.daily_lesson.today_lesson_book}</h5>
                <p className="mt-3 font-bold">{data.title}</p>
                <p className="mt-3">{data.subject}</p>

                <div className="mx-auto mt-4 max-w-[475px]">
                    <ul id="tabs" className="flex justify-between mx-auto text-start">
                        {convertedData[3][0] && (
                            <li className="font-bold w-[80px] xs:w-[90px] relative pb-1">
                                <a className="cursor-pointer" onClick={() => {
                                    setCurrentTab(currentTab => 3);
                                    !analyticsPass && analytics.useAnalytics(events[1], { category: 'Book' });
                                }}>
                                    {bookLocalization[0]}
                                </a>
                                <div className={`absolute bottom-0 left-0 right-0 h-[3px] rounded ${currentTab === 3 ? "bg-primary" : "bg-base-text"}`}></div>
                            </li>
                        )}
                        {convertedData[4][0] && (
                            <li className="font-bold w-[80px] xs:w-[90px] relative pb-1">
                                {" "}
                                <a className="cursor-pointer" onClick={() => {
                                    setCurrentTab(currentTab => 4);
                                    !analyticsPass && analytics.useAnalytics(events[1], { category: 'Presentation' });
                                }}>{bookLocalization[1]}</a>
                                <div className={`absolute bottom-0 left-0 right-0 h-[3px] rounded ${currentTab === 4 ? "bg-primary" : "bg-base-text"}`}></div>
                            </li>
                        )}
                        {convertedData[5][0] && (
                            <li className="font-bold w-[80px] xs:w-[90px] relative pb-1">
                                {" "}
                                <a className="cursor-pointer" onClick={() => {
                                    setCurrentTab(currentTab => 5);
                                    !analyticsPass && analytics.useAnalytics(events[1], { category: 'Practical' });
                                }}>{bookLocalization[2]}</a>
                                <div className={`absolute bottom-0 left-0 right-0 h-[3px] rounded ${currentTab === 5 ? "bg-primary" : "bg-base-text"}`}></div>
                            </li>
                        )}
                    </ul>

                    {/* <!-- Tab Contents --> */}
                    <div id="tab-contents">
                        {convertedData[3][0] && (
                            <div id="first" className={`pt-4 mt-4 relative ${currentTab !== 3 ? "hidden" : ""}`}>
                                <img className="w-full" src={convertedData[3][0]} alt="" />
                                <button
                                    className="absolute inset-0 z-10 flex justify-center items-center"
                                    onClick={toggleModal}
                                >
                                    <img src={Zoom_In} width="64" height="64" alt="" />
                                </button>
                            </div>
                        )}
                        {convertedData[4][0] && (
                            <div id="second" className={`pt-4 mt-4 relative ${currentTab !== 4 ? "hidden" : ""}`}>
                                <img className="w-full" src={convertedData[4][0]} alt="" />
                                <button
                                    className="absolute inset-0 z-10 flex justify-center items-center"
                                    onClick={toggleModal}
                                >
                                    <img src={Zoom_In} width="64" height="64" alt="" />
                                </button>
                            </div>
                        )}
                        {convertedData[5][0] && (
                            <div id="third" className={`pt-4 mt-4 relative ${currentTab !== 5 ? "hidden" : ""}`}>
                                <img className="w-full" src={convertedData[5][0]} alt="" />
                                <button
                                    className="absolute inset-0 z-10 flex justify-center items-center"
                                    onClick={toggleModal}
                                >
                                    <img src={Zoom_In} width="64" height="64" alt="" />
                                </button>
                            </div>
                        )}
                    </div>

                </div>
            </div>

            <BookModal
                ref={modalRef}
                src={convertedData[currentTab][0]}
                toggleModal={toggleModal}
                timeoutState={timeoutState}
            />
        </>
    )
}

export default LessonBook