import classNames from "classnames";
import { motion } from "framer-motion";
import { Wrong } from "../../../../../assets/img";
import { PronunciationErrors } from "../../../../../components/Constants";
import { useLocalization } from "../../../../../hooks/useLocalization";
import useWindowSize from "../../../../../hooks/useWindowSize";

const SpeakingLabWrong = (props) => {

    const strings = useLocalization();
    const { 
        buttonClick, 
        textInfo = [],
        textInfoTitle = strings.speaking_lesson.speaking_lab.wrong_answer,
        skipButtonClick = () => {},
        skipButtonCondition = false
    } = props;

    const windowSize = useWindowSize();
    const isMobile = windowSize?.width < 640;

    const containerVariants = isMobile
        ? {
            initial: { opacity: 0, y: '100%' },
            animate: { opacity: 1, y: 0 },
            exit: { opacity: 0, y: '100%' },
            transition: { duration: 0.6, ease: 'easeOut' },
        }
        : {
            initial: { opacity: 0, y: 50 },
            animate: { opacity: 1, y: 0 },
            exit: { opacity: 0, y: '100%' },
            transition: { duration: 0.5, ease: 'easeOut' },
        };

    return (
        <motion.div
            className={classNames("flex items-center justify-center mt-5", {
                "fixed bottom-0 left-0 right-0 mb-[70px] border-t border-t-nav-button-border": isMobile
            })}
            variants={containerVariants}
            initial="initial"
            animate="animate"
            exit="exit"
        >
            <div
                className={classNames("bg-[#FFDFE0] p-6 w-full shadow-popup", {
                    "max-w-full border-t border-t-nav-button-border" : isMobile,
                    "max-w-md": !isMobile
                })}
            >    
                <p className="text-red text-[20px] font-bold align-middle text-start mb-2">
                    <img src={Wrong ?? ""} className="inline-flex mr-1.5" width="20" height="20" alt="check-mark"/>
                    {textInfoTitle}
                </p>
                {textInfo.length > 0 &&
                    <div className="mb-4">
                        <span className="font-bold">{strings.onboarding_exercises.your_answer}:{" "}</span>
                        <span className="font-semibold">
                            {textInfo
                                .filter(item => 
                                    PronunciationErrors.OMISSION === item.error_type ||
                                    PronunciationErrors.MISPRONUNCIATION === item.error_type ||
                                    PronunciationErrors.NONE === item.error_type
                                )
                                .map((item, index) => (
                                    <span
                                        key={index}
                                        className={classNames("font-bold", {
                                            "text-red" : PronunciationErrors.OMISSION === item.error_type,
                                            "text-secondary" : PronunciationErrors.MISPRONUNCIATION === item.error_type,
                                            "text-green" : PronunciationErrors.NONE === item.error_type,
                                        })}
                                    >
                                        {item.word}{" "}
                                    </span>
                                ))
                            }
                        </span>
                    </div>
                }

                <button
                    type="button"
                    className="button w-full bg-[#FF4B4B] hover:bg-[#FF4B4B]/80 transition-colors duration-300 mb-2 mx-auto text-white"
                    onClick={buttonClick}
                >
                    {strings.speaking_lesson.speaking_lab.try_again_button}
                </button>

                {skipButtonCondition &&
                    <button
                        type="button"
                        className="text-button text-[14px] underline font-bold text-base-text hover:text-base-text/80 w-full mx-auto underline-offset-2"
                        onClick={skipButtonClick}
                    >
                        {strings.speaking_lesson.speaking_lab.skip_this_question}
                    </button>
                }
            </div>
        </motion.div>
    );
}

export default SpeakingLabWrong;