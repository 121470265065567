import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ModalContent } from "../../../../../components";
import { useLocalization } from "../../../../../hooks/useLocalization";
import { closeModal } from "../../../../../utils";
import { CreateAutoMemberCampaign as CreateAutoMemberCampaignService } from "../../../../../services/Market/MarketService";
import Counter from "../../Common/Counter";
import { useLocation } from "react-router-dom";
import { SourceTypes } from "../../../../../components/Constants";

const CreateAutoMemberCampaign = (props) => {

    const { 
        memberId, 
        getAutoApplyCampaigns, 
        modalContentRef, 
        modalOverlayRef, 
        isModalVisible, 
        setIsModalVisible, 
        isWithinWorkingHours,
        mentorPhoneNumber,
        setRenewProductList
    } = props;
    const strings = useLocalization();
    const location = useLocation();

    // 10 saniye sonra endpointler tetikleniyor
    const [isActivated, setIsActivated] = useState(false);
    
    // Kampanya bilgilerini alıyoruz
    const hourlyDiscountRender = useSelector(state => state.componentRenderStore.Market.HourlyDiscount);
    const isMobile = location.pathname.includes("/mobile_market_package_catalog");

    const handleTimerEnd = () => {
        setIsModalVisible(false);
        closeModal(modalOverlayRef, modalContentRef);
    }

    // 10 saniye sonra endpointleri tetiklemek için kullanılıyor
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsActivated(true);
        }, 10000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if(isActivated)
            CreateAutoMemberCampaignService(memberId, isMobile ? SourceTypes.Mobile : SourceTypes.Web)
                .then(result => {
                    if(result.status === 200) {
                        const resultContent = JSON.parse(result.content);
                        
                        if(!resultContent.success > 0) 
                            return;

                        getAutoApplyCampaigns();
                        setRenewProductList(prevState => prevState + 1);
                    }
                })
                .catch();
    }, [isActivated]);

    return (
        <>
            <ModalContent
                ref={modalContentRef}
                overlayRef={modalOverlayRef}
                closeAction={() => setIsModalVisible(false)}
                show={isModalVisible}
                className="max-h-max"
            >
                <p className="font-bold text-center text-[18px]">
                    {strings.market.speakingLesson.auto_create_campaign.title}
                </p>
                <div className="flex flex-col items-center">
                    <p className="text-center" dangerouslySetInnerHTML={{ __html: strings.market.speakingLesson.auto_create_campaign.description.replace("#rate#", hourlyDiscountRender?.rate) }}></p>
                    <div className="mt-5 -mb-2">
                        {hourlyDiscountRender?.visibility && 
                            <Counter 
                                endDate={hourlyDiscountRender.endDate} 
                                onTimerEnd={handleTimerEnd} 
                                variant="blue"
                            />
                        }
                    </div>
                </div>
                <div className="flex flex-col items-center btn-outer-div">
                    <button 
                        className="button button-mt secondary-button w-full"
                        onClick={() => closeModal(modalOverlayRef, modalContentRef)}
                    >
                        {strings.market.speakingLesson.auto_create_campaign.check_packages}
                    </button>
                    {(isWithinWorkingHours && mentorPhoneNumber.length > 0) && 
                        <a 
                            className="button button-mt secondary-button-outline w-full"
                            href={`tel:${mentorPhoneNumber}`}
                        >
                            {strings.market.speakingLesson.auto_create_campaign.call_mentor}
                        </a>
                    }
                </div>
            </ModalContent>
        </>
    );
}

export default CreateAutoMemberCampaign;