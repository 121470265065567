import React, { useEffect, useState } from 'react';
import { Loading, TermDropDown } from '../../../components';
import useAnalytics from '../../../hooks/useAnalytics';
import useAuth from '../../../hooks/useAuth';
import { useLocalization } from '../../../hooks/useLocalization';
import { GetFreezeInformationService, GetMissedInformationService } from '../../../services/Support/SupportService';

import { addTimeZone, getTimeZone } from '../../../utils';
import FreezeSuccess from "../Freeze/FreezeSuccess";
import MissedCall from './MissedCall';
import MissedCallNoLessonResult from './MissedCallNoLessonResult';
import MissedCallNoRight from './MissedCallNoRight';
import MissedCallNotInCorrectTime from './MissedCallNotInCorrectTime';
import MissedCallRightUsed from './MissedCallRightUsed';


const MissedCallHome = () => {

  const strings = useLocalization();
  const analytics = useAnalytics();
  const { member, token } = useAuth();
  const timeZone = getTimeZone(member.Timezone);

  const terms =  typeof(member.Terms) !== "undefined" && JSON.parse(member.Terms).filter(term => term.Status === 1);

  const [termId, setTermId] = useState(false);
  const [lesson, setLesson] = useState('');
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState({});
  const [freezeData, setFreezeData] = useState({});

  const getFreezeData = async termId => {
    try {
      const result = await GetFreezeInformationService(termId, token);
      if (result.statusCode === 200) {
        setFreezeData(JSON.parse(result.message));
      } else {

        if (result.message) {
          analytics.useAnalytics('Error_Occured', {
            error: `${result.message}`,
          });
        }
        else {
          analytics.useAnalytics('Error_Occured', {
            error: `An unexpected error occurred.`,
          });
        }
      }

    } catch (error) {
      analytics.useAnalytics('Error_Occured', {
        error: `An unexpected error occurred.`,
      });
    }
  };

  const getMissedCall = async termId => {
    try {
      const result = await GetMissedInformationService(termId, token);

      if (result.status === 200) {
        setData(JSON.parse(result.content));
        setLoading(false);
      } else {

        if (result.content) {
          analytics.useAnalytics('Error_Occured', {
            error: `${result.content}`,
          });
        }
        else {
          analytics.useAnalytics('Error_Occured', {
            error: `An unexpected error occurred.`,
          });
        }

      }

    } catch (error) {
      setLoading(false);
      analytics.useAnalytics('Error_Occured', {
        error: `An unexpected error occurred.`,
      });
    }
  };

  //from child dropdown
  const changeTerm = term => {
    const type = `${term.ProductName} / ${addTimeZone(term.LessonTime, timeZone)} / ${term.LessonDuration} ${strings.general_information_messages.minute}`;

    setTermId(term.TermId);
    setLesson(lesson => type);
    setLoading(loading => true);
  };

  const Content = () => {
    if (data.activeMissedCall !== null) {
      return <MissedCallRightUsed data={data.activeMissedCall} />;
    } else if (data.missedRightCount === 0) {
      return <MissedCallNoRight data={data.missedLessonDates} />;
    } else if (data.availableTimeRangeStatus === 1 && !data.isThereMissedLessonResult) {
      return <MissedCallNoLessonResult />;
    } else if (data.availableTimeRangeStatus === 0) {
      return <MissedCallNotInCorrectTime />;
    } else {
      return <MissedCall termId={termId} getMissedCall={getMissedCall} />;
    }
  };

  useEffect(() => {
    if (termId) {
      getFreezeData(termId);
      getMissedCall(termId);
    }
  }, [termId])

  return (
    <>
      <div className="main-content">
        <div className="mt-5 gray-section px-[10px] xs:px-[10px] text-start">
          <TermDropDown terms={terms} changeTerm={changeTerm} />
        </div>

        {loading ? (
          <Loading />
        )
          :
          (
            freezeData.activeFreezeEndDate === null ?
              (
                <Content />
              )
              :
              (
                <FreezeSuccess endDate={freezeData.activeFreezeEndDate} />
              )
          )}

        <div className="left-margin-bottom"></div>
      </div>
    </>
  )
}

export default MissedCallHome