import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocalization } from '../../hooks/useLocalization';
import { url } from '../../routes/utility';
import { ProgramBadge } from '../Market/components';
import useAnalytics from '../../hooks/useAnalytics';
import { EventLogs } from '../../components/Constants';

const InnerCategory = () => {

    const strings = useLocalization();
    const navigate = useNavigate();
    const analytics = useAnalytics();
    const location = useLocation();
    const data = location.state?.data;
    const language = useSelector(state => state.localizationStore.language);

    const handleButton = (item) => {
        const level = item.level;
        
        if(level === "beginner")
            analytics.useAnalytics(EventLogs.RESOURCES_GRAMMAR_VIDEOS_BEGINNER_SCREEN_VIEWED);

        if(level === "intermediate")
            analytics.useAnalytics(EventLogs.RESOURCES_GRAMMAR_VIDEOS_INTERMEDIATE_SCREEN_VIEWED);

        if(level === "advanced")
            analytics.useAnalytics(EventLogs.RESOURCES_GRAMMAR_VIDEOS_ADVANCED_SCREEN_VIEWED);        

        level?.length > 0 && navigate(url("resources.videos", { level }), { state: { "videoData": item } });
        (!level || level.length === 0) && navigate(url("resources.videos"), { state: { "videoData": item } });
    }

    useEffect(() => {
        //Sayfa ilk yüklendiğinde yukarıya scroll ettiriliyor.
        window.scrollTo({ top: 0, behavior: "smooth" });

        // Sayfa ilk yüklendiğinde eğer bir alt kategory yoksa direkt videoların olduğu sayfaya gidiliyor
        data.length === 1 && handleButton(data[0])
    }, [])

    return (
        <div className="main-content">
            <div className="transparent-section mt-5">
                <h5 className="text-secondary">{strings.dashboard.student.all_sources}</h5>
                <p className="mt-3 text-center">{strings.dashboard.student.all_sources_description}</p>

                {data?.map((item, index) => (
                    <ProgramBadge
                        key={index}
                        title={language === "tr" ? item.title : item.translation[language]}
                        onClick={() => handleButton(item)}
                    />
                ))}
            </div>
        </div>
    )
}

export default InnerCategory