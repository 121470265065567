import classNames from "classnames";
import { motion } from 'framer-motion';
import { CheckMark } from "../../../../../assets/img";
import { EventLogs, PronunciationErrors } from "../../../../../components/Constants";
import { useLocalization } from "../../../../../hooks/useLocalization";
import useWindowSize from "../../../../../hooks/useWindowSize";
import useAnalytics from "../../../../../hooks/useAnalytics";

const SpeakingLabCorrect = (props) => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const { 
        buttonClick, 
        textInfoTitle = strings.speaking_lesson.speaking_lab.correct_answer,
        textInfo = [],
        buttonText = strings.market.information.button,
    } = props;

    const windowSize = useWindowSize();
    const isMobile = windowSize?.width < 640;

    const containerVariants = isMobile
        ? {
            initial: { opacity: 0, y: '100%' },
            animate: { opacity: 1, y: 0 },
            transition: { duration: 0.6, ease: 'easeOut' },
        }
        : {
            initial: { opacity: 0, y: 50 },
            animate: { opacity: 1, y: 0 },
            transition: { duration: 0.5, ease: 'easeOut' },
        };


    const handleButtonClick = () => {
        buttonClick();
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if(buttonText === strings.video_practice.word_card.word_pronunciation)
            analytics.useAnalytics(EventLogs.SPEAKING_LAB_CREATE_SENTENCE_PRONOUNCE);
    }

    return (
        <motion.div
            className={classNames("flex items-center justify-center mt-5", {
                "fixed bottom-0 left-0 right-0 mb-[70px]": isMobile
            })}
            variants={containerVariants}
            initial="initial"
            animate="animate"
        >
            <div
                className={classNames("bg-[#DCF9DA] p-6 w-full shadow-popup", {
                    "max-w-full border-t border-t-nav-button-border" : isMobile,
                    "max-w-md" : !isMobile
                })}
            >
                <p className="text-green text-[20px] font-bold align-middle text-start mb-2">
                    <img src={CheckMark ?? ""} className="inline-flex mr-1.5" width="20" height="20" alt="check-mark"/>
                    {textInfoTitle}
                </p>
                {textInfo.length > 0 && 
                    <div className="mb-4">
                        <span className="font-bold">{strings.onboarding_exercises.your_answer}:{" "}</span>
                        <span className="font-semibold">
                            {textInfo
                                .filter(item => 
                                    PronunciationErrors.OMISSION === item.error_type ||
                                    PronunciationErrors.MISPRONUNCIATION === item.error_type ||
                                    PronunciationErrors.NONE === item.error_type
                                )
                                .map((item, index) => (
                                    <span
                                        key={index}
                                        className={classNames("font-bold", {
                                            "text-red" : PronunciationErrors.OMISSION === item.error_type,
                                            "text-secondary" : PronunciationErrors.MISPRONUNCIATION === item.error_type,
                                            "text-green" : PronunciationErrors.NONE === item.error_type,
                                        })}
                                    >
                                        {item.word}{" "}
                                </span>
                            ))}
                        </span>
                    </div>
                }
                <button
                    type="button"
                    className="button w-full bg-green hover:bg-green/80 transition-colors duration-300 mb-2 mx-auto text-white"
                    onClick={handleButtonClick}
                >
                    {buttonText}
                </button>
            </div>
        </motion.div>
    )
}

export default SpeakingLabCorrect;