import React, { useState, useEffect } from "react";
import { useLocalization } from "../../../../hooks/useLocalization";
import { getTimeZone, ISOStringToDate } from "../../../../utils";
import classNames from "classnames";

const Counter = ({ endDate, onTimerEnd, variant = "white" }) => {
    const strings = useLocalization();
    const timeZone = getTimeZone();

    const calculateTimeLeft = () => {
        const endDateSeconds = endDate.split("T")[1].split(":")[2];
        const removeZFromDate = endDate.replace("Z", "");
        const difference = new Date(ISOStringToDate(removeZFromDate, timeZone) + ":" + endDateSeconds) - new Date();
        
        if (difference > 0) {
            return {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
                seconds: Math.floor((difference % (1000 * 60)) / 1000),
            };
        }
        return null;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        if (timeLeft) {
            const timer = setInterval(() => {
                const newTimeLeft = calculateTimeLeft();
                if (newTimeLeft) {
                    setTimeLeft(newTimeLeft);
                } else {
                    clearInterval(timer);
                    onTimerEnd();
                }
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [timeLeft, onTimerEnd]);

    if (!timeLeft) return null;

    return (
        <div className="flex gap-1 justify-center">
            {timeLeft.days !== 0 && (
                <div className={classNames("flex flex-col items-center justify-center w-12 h-12 rounded-lg text-[18px]", {
                    "text-white bg-gradient-to-r from-primary to-blue-600" : variant === "blue",
                    "bg-white" : variant === "white",
                })}>
                    <p className="font-bold">{timeLeft.days}</p>
                    <p className="text-[10px] -mt-1">{strings.dashboard.student.day}</p>
                </div>
            )}
            {timeLeft.hours !== 0 && (
                <div className={classNames("flex flex-col items-center justify-center w-12 h-12 rounded-lg text-[18px]", {
                    "text-white bg-gradient-to-r from-primary to-blue-600" : variant === "blue",
                    "bg-white" : variant === "white",
                })}>
                    <p className="font-bold">{timeLeft.hours}</p>
                    <p className="text-[10px] -mt-1">{strings.dashboard.student.hour}</p>
                </div>
            )}
            <div className={classNames("flex flex-col items-center justify-center w-12 h-12 rounded-lg text-[18px]", {
                "text-white bg-gradient-to-r from-primary to-blue-600" : variant === "blue",
                "bg-white" : variant === "white",
            })}>
                <p className="font-bold">{timeLeft.minutes}</p>
                <p className="text-[10px] -mt-1">{strings.dashboard.student.minute}</p>
            </div>
            <div className={classNames("flex flex-col items-center justify-center w-12 h-12 rounded-lg text-[18px]", {
                "text-white bg-gradient-to-r from-primary to-blue-600" : variant === "blue",
                "bg-white" : variant === "white",
            })}>
                <p className="font-bold">{timeLeft.seconds}</p>
                <p className="text-[10px] -mt-1">{strings.dashboard.student.second}</p>
            </div>
        </div>
    );
};

export default Counter;