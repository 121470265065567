import React from 'react'
import { useLocalization } from '../../hooks/useLocalization';
import CandidateAndInactiveStudentCommon from './components/CandidateAndInactiveStudentCommon';
import useAuth from '../../hooks/useAuth';

const DashboardCandidate = () => {
    const strings = useLocalization();
    const { member } = useAuth();

    return (
        <>
            <div className="pt-6">
                <p className="font-bold text-xl">{`${strings.dashboard.none_user.title_1} ${member?.FullName == undefined ? '' : member?.FullName},`}</p>
                <p className="text-lg">{strings.dashboard.none_user.title_2}</p>
            </div>

            <CandidateAndInactiveStudentCommon />


        </>
    )
}

export default DashboardCandidate