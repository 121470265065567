import classNames from "classnames";
import React, { useRef, useState } from "react";
import Lottie from "lottie-react";
import dialog from "../../../../assets/animation/dialog.json";
import { ModalOverlay, ModalTwoButtons } from "../../../../components";
import { useLocalization } from "../../../../hooks/useLocalization";
import { AddMemberSpeakingLab, RestartMemberSpeakingLab } from "../../../../services/SpeakingLab/SpeakingLabService";
import { openModal } from "../../../../utils";
import { EventLogs, SpeakingLabResultType } from "../../../../components/Constants";
import { useNavigate } from "react-router-dom";
import { url } from "../../../../routes/utility";
import useAnalytics from "../../../../hooks/useAnalytics";

const SpeakingLabStart = (props) => {

    const {
        clickAction,
        setCurrentExerciseIndex,
        exerciseData,
        loading
    } = props;

    const strings = useLocalization();
    const analytics = useAnalytics();
    const navigate = useNavigate();

    const modalRef = useRef();
    const modalOverlayRef = useRef();

    const [continueExerciseButtonLoading, setContinueExerciseButtonLoading] = useState(false);

    const addMemberSpeakingLab = () => {
        setContinueExerciseButtonLoading(true);
        
        AddMemberSpeakingLab({
          memberId: exerciseData?.memberId,
          dailyDate: exerciseData?.selectedDate,
          bookUnitId: exerciseData?.bookUnitId,
          exerciseSequence: exerciseData?.currentExerciseIndex + 1,
          speakingLabResultType: SpeakingLabResultType.STARTED_AGAIN
        }) 
          .then((result) => {
            if(result.status === 200)
                clickAction();
            
            setContinueExerciseButtonLoading(false);
          })
          .catch()
    }

    const restartMemberSpeakingLab = () => {
        const model = {
            memberId: exerciseData?.memberId,
            dailyDate: exerciseData?.selectedDate,
            bookUnitId: exerciseData?.bookUnitId,
            exerciseSequence: exerciseData?.lastSequence
        }

        RestartMemberSpeakingLab(model)
            .then(result => {
                if(result.status === 200) {
                    setCurrentExerciseIndex(0);
                    clickAction();
                }
            })
            .catch()
    }

    const handleClickStartButton = () => {
        analytics.useAnalytics(EventLogs.SPEAKING_LAB_START_CLICK);

        if(exerciseData?.lastSequence <= 1) {            
            // Eğer son sequence 1 ise, addMemberSpeakingLab fonksiyonunu çağır ve fonksiyondan çık
            // Bunun amacı kullanıcıya ilk soruda, kaldığınız yerden devam etmek ister misiniz? modalını açmamaktır.
            if(exerciseData?.lastSequence === 1) {
                addMemberSpeakingLab();
                return;
            }
            // Aksi takdirde, clickAction fonksiyonunu çağır
            clickAction();
        } else {
            openModal(modalOverlayRef, modalRef);
        }
    }

    return (
        <>
            <button
                className="ml-auto mt-5 border z-50 border-onboarding-border hover:bg-onboarding-bg-select focus:bg-onboarding-bg-select focus:text-base gap-2 h-8 max-w-max px-3 flex flex-row items-center justify-center rounded-[10px]"
                onClick={() => navigate(url("speakinglesson.dailylesson"))}
            >
                <span className="material-symbols-outlined">keyboard_return</span>
                {strings.ai_chatbot.back}
            </button>
            <div className="flex flex-col items-center justify-center h-[60vh] relative">
                <div className="max-md:-mt-14">
                    <Lottie 
                        animationData={dialog} 
                        loop={true}
                        autoplay={true}
                        style={{ height: 340, width: 340 }}
                    />
                </div>
                <div className="flex flex-col items-center justify-between -mt-10 z-10 gap-10 md:gap-48">
                    <p className="text-center font-bold max-w-[500px]">
                        {strings.speaking_lesson.speaking_lab.description}
                    </p>
                    <button
                        type="button"
                        className={classNames("button w-[95%] xs:w-[330px] primary-button mb-2 mx-auto focus:!bg-primary", {
                            "opacity-25 disabled:pointer-events-none": loading
                        })}
                        onClick={handleClickStartButton}
                    >
                        {loading 
                            ? strings.general_information_messages.processing
                            : strings.speaking_lesson.quiz.start
                        }
                    </button>
                </div>
            </div>
            
            <ModalOverlay ref={modalOverlayRef} />
            <ModalTwoButtons
                ref={modalRef}
                overlayRef={modalOverlayRef}
                title={strings.modal.transaction_information}
                message={strings.speaking_lesson.speaking_lab.start_again_modal_text}
                disableText={strings.general_information_messages.processing}
                buttonText1={strings.speaking_lesson.quiz.continue}
                buttonClick1={() => {
                    addMemberSpeakingLab();
                    analytics.useAnalytics(EventLogs.SPEAKING_LAB_PROGRESS_MODAL_CONTINUE);
                }}
                isDisableButtonOne={loading || continueExerciseButtonLoading}
                buttonText2={strings.speaking_lesson.speaking_lab.start_again}
                isDisableButtonTwo={loading}
                buttonClick2={() => {
                    restartMemberSpeakingLab();
                    analytics.useAnalytics(EventLogs.SPEAKING_LAB_PROGRESS_MODAL_START_OVER);
                }}
            />
        </>
    )
}

export default SpeakingLabStart;