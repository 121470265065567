import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Check, EditText } from "../../assets/svg";
import { Checkbox, Loading, ModalOneButton, ModalOverlay, Radio } from "../../components";
import { ToggleButton } from "../../components/Buttons";
import { AIKeys, EventLogs, MemberDemandIds, MemberStatus, SourceTypes } from "../../components/Constants";
import useAiRouting from "../../hooks/useAiRouting";
import useAnalytics from "../../hooks/useAnalytics";
import useAuth from "../../hooks/useAuth";
import { useLocalization } from "../../hooks/useLocalization";
import useMemberName from "../../hooks/useMemberName";
import useMemberSurvey from "../../hooks/useMemberSurvey";
import { url } from "../../routes/utility";
import { UpdateMemberProfileStatus } from "../../services/Profile/ProfileService";
import {
    CreateOrUpdateMemberDemandsAndReason,
    GetMemberProfileStatus,
    GetStudentDemandsAndReasons,
    UpdateMemberName
} from "../../services/Profile/ProfileSettingsService";
import { SendTicketService } from "../../services/Support/SupportService";
import { closeModal, getDefaultPhotoByGender, openModal } from "../../utils";
import { DeleteAccount } from "./components";

const ProfileSettings = () => {

    const strings = useLocalization();
    const analytics = useAnalytics();

    const { member, token } = useAuth();
    const { survey, surveyLoading } = useMemberSurvey();
    const navigate = useNavigate();
    const useCaseId = useAiRouting(AIKeys.LEVEL_FINDER);
    const memberName = useMemberName(member?.MemberId);

    const modalRef = useRef();
    const modalOverlayRef = useRef();

    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [modalText, setModalText] = useState("");

    const [lessonMethod, setLessonMethod] = useState(false);
    const [feedbackType, setFeedbackType] = useState(false);
    const [askPersonalInformations, setAskPersonalInformations] = useState(false);
    const [askDailyQuestion, setAskDailyQuestion] = useState(false);
    const [selectedLearnEnglishReasons, setSelectedLearnEnglishReasons] = useState([]);
    const [answer, setAnswer] = useState(-1);
    const [editedMemberName, setEditedMemberName] = useState("");
    const [isEditingMemberName, setIsEditingMemberName] = useState(false);
    const [profileVisibility, setProfileVisibility] = useState(false);

    const levelNames = [
        { id: 2, name: strings.onboarding.first.level1 },
        { id: 3, name: strings.onboarding.first.level2 },
        { id: 4, name: strings.onboarding.first.level3 },
        { id: 5, name: strings.onboarding.first.level4 }
    ]

    const getStudentDemandsAndReasons = async (memberId) => {

        const result = await GetStudentDemandsAndReasons(memberId);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);

            //Kullanıcının seçili ingilizce öğrenme nedenleri set ediliyor.
            setSelectedLearnEnglishReasons(setSelectedLearnEnglishReasons => resultContent.activeMemberReasonTypeIds);

            //Kullanıcının kayıtlı olan öğrenme metotu set ediliyor.
            let lessonMethod = resultContent?.memberDemands.find(demand => demand.demandTypeId === MemberDemandIds.LESSON_METHOD);
            if (lessonMethod) {
                lessonMethod.status === 1
                    ? setLessonMethod(lessonMethod => true)
                    : setLessonMethod(lessonMethod => false)
            } else {
                setLessonMethod(lessonMethod => false)
            }

            //Kullanıcının kayıtlı olan geri bildirim tipi set ediliyor.
            let feedbackType = resultContent?.memberDemands.find(demand => demand.demandTypeId === MemberDemandIds.FEEDBACK_TYPE);
            if (feedbackType) {
                feedbackType.status === 1
                    ? setFeedbackType(feedbackType => true)
                    : setFeedbackType(feedbackType => false)
            } else {
                setFeedbackType(feedbackType => false)
            }

            //Kullanıcının kayıtlı olan kişisel bilgileri sor/sorma durumu set ediliyor.
            let personalInformation = resultContent?.memberDemands.find(demand => demand.demandTypeId === MemberDemandIds.PERSONAL_INFORMATION);
            if (personalInformation) {
                personalInformation.status === 1
                    ? setAskPersonalInformations(askPersonalInformations => true)
                    : setAskPersonalInformations(askPersonalInformations => false)
            } else {
                setAskPersonalInformations(askPersonalInformations => false)
            }

            //Kullanıcının kayıtlı olan günlük bilgileri sor/sorma durumu set ediliyor.
            let dailyQuestion = resultContent?.memberDemands.find(demand => demand.demandTypeId === MemberDemandIds.DAILY_QUESTION);
            if (dailyQuestion) {
                dailyQuestion.status === 1
                    ? setAskDailyQuestion(askDailyQuestion => true)
                    : setAskDailyQuestion(askDailyQuestion => false)
            } else {
                setAskDailyQuestion(askDailyQuestion => false)
            }
        }

        setLoading(false);
    }

    const handleLessonMethod = () => {
        setLessonMethod(lessonMethod => !lessonMethod);
    }

    const handleFeedbackType = () => {
        setFeedbackType(feedbackType => !feedbackType);
    }

    /*
        * if -> Tıklanılan reason eğer seçili reason'lar arasında yoksa ve seçili değerler maksimum seçme sınırına ulaştıysa modal açılıyor.
        * else if -> Seçilen değer, seçili reason'lar içerisinde var ise kaldırılıyor.
        * else if -> Yeni reason seçili reason'lar içerisine ekleniyor.
    */
    const handleReasonCheckboxChange = (reasonToLearnEnglishId) => {
        // Checkbox durumunu günceller
        if (!selectedLearnEnglishReasons.includes(reasonToLearnEnglishId) && selectedLearnEnglishReasons.length === 5) {
            setModalText(modalMessage => strings.onboarding.information_messages.max_choice_message);
            openModal(modalOverlayRef, modalRef);
        } else if (selectedLearnEnglishReasons.includes(reasonToLearnEnglishId)) {
            setSelectedLearnEnglishReasons(selectedReasons => selectedReasons.filter((id) => reasonToLearnEnglishId !== id));
        } else {
            setSelectedLearnEnglishReasons(selectedReasons => [...selectedReasons, reasonToLearnEnglishId]);
        }
    };

    // Seviye Testi AI Butonuna tıklandığında yönlendirmeyi sağlayan fonksiyon.
    const handleNavigateLevelAi = () => {
        navigate(url("speakinglesson.chatbot", { useCaseId: useCaseId }));
    }

    const createOrUpdateDemandsAndReasons = async () => {

        let valid = true;

        if (selectedLearnEnglishReasons.length === 0) {
            valid = false;
            setModalText(strings.profile.reason_to_learn_english.error_message);
            openModal(modalOverlayRef, modalRef);

            getStudentDemandsAndReasons(member.MemberId);
        }

        if (valid) {
            setButtonLoading(buttonLoading => true);
            let model = {
                sourceType: SourceTypes.Web,
                memberId: member.MemberId,
                memberReasonTypeIds: selectedLearnEnglishReasons,
                levelId: answer,
                memberDemands: [
                    {
                        demandTypeId: MemberDemandIds.LESSON_METHOD,
                        status: lessonMethod ? 1 : 0
                    },
                    {
                        demandTypeId: MemberDemandIds.FEEDBACK_TYPE,
                        status: feedbackType ? 1 : 0
                    },
                    {
                        demandTypeId: MemberDemandIds.PERSONAL_INFORMATION,
                        status: askPersonalInformations ? 1 : 0
                    },
                    {
                        demandTypeId: MemberDemandIds.DAILY_QUESTION,
                        status: askDailyQuestion ? 1 : 0
                    }
                ]
            };

            const result = await CreateOrUpdateMemberDemandsAndReason(model);

            if (result.status === 200) {
                setModalText(strings.profile.success_message);

                /*
                    Profil güncelleme başarılı oldukça event atılıyor.
                    Bu event sadece analytics'e gönderiliyor.
                */
                analytics.useAnalytics(
                    EventLogs.PROFILE_SETTINGS_UPDATED,
                    undefined,
                    true,
                    false
                );

            } else {
                getStudentDemandsAndReasons(member.MemberId);
                setModalText(strings.onboarding.information_messages.an_error_occured);
            }

            openModal(modalOverlayRef, modalRef);
            setButtonLoading(false);
        }
    }

    const handleDeleteAccount = async () => {
        let msg = "Hesabımı silmek istiyorum";

        const result = await SendTicketService(member.MemberId, msg, token);

        if (result.statusCode === 200) {
            analytics.useAnalytics(EventLogs.ACCOUNT_DELETE_REQUEST_SEND);
        } else {
            if (result.message) {
                analytics.useAnalytics("Error_Occured", {
                    error: `${result.message}`,
                });
            } else {
                analytics.useAnalytics("Error_Occured", {
                    error: `An unexpected error occurred.`,
                });
            }
        }
    };

    const handleEditNameButton = () => {
        if (isEditingMemberName) {
            handleUpdateName();
        } else {
            setIsEditingMemberName(true);
        }
    }

    const handleUpdateName = () => {
        setIsEditingMemberName(false);
        UpdateMemberName(member?.MemberId, editedMemberName)
            .then(result => {
                if(result.status !== 200) {
                    setEditedMemberName(memberName);
                    analytics.useAnalytics(EventLogs.PROFILE_NAME_UPDATED, undefined, false, true);
                }
            })
            .catch()
    }

    const handleKeyDownUpdateName = (e) => {
        if(e.keyCode === 13) {
            handleUpdateName();
        }
    }

    const updateMemberProfileStatus = (e) => {
        UpdateMemberProfileStatus(member?.MemberId, e.target.checked)
            .then(result => {
                if(result.status === 200) {
                    setProfileVisibility(JSON.parse(result.content));
                    e.target.checked
                        ? analytics.useAnalytics(EventLogs.PROFILE_LOCKED, undefined, false, true)
                        : analytics.useAnalytics(EventLogs.PROFILE_UNLOCKED, undefined, false, true)
                }
            })
            .catch()
    }

    useEffect(() => {
        analytics.useAnalytics(EventLogs.PROFILE_SCREEN_VIEW, undefined, true, false);

        if (member.MemberId) {
            setLoading(true);
            getStudentDemandsAndReasons(member.MemberId);
        }
    }, []);

    //Kullanıcının daha önce seçtiği cevap var ise set ediliyor.
    useEffect(() => {
        if (!surveyLoading) {
            setAnswer(answer => survey.second === 0 ? -1 : survey.second);
        }
    }, [surveyLoading]);

    useEffect(() => {
        if(memberName.length > 0) {
            const tempMemberName = memberName.length === 0 || null
                ? strings.speaking_lesson.speaking_lesson_user.it_is_not_specified
                : memberName;

            setEditedMemberName(tempMemberName);
        }
    }, [memberName])

    useEffect(() => {
        GetMemberProfileStatus(member?.MemberId)
            .then(result => {
                if(result.status === 200)
                    setProfileVisibility(JSON.parse(result.content));
            })
            .catch()
    }, []);

    return (
        <div className="main-content">
            <div className="flex flex-col gap-2 items-center justify-center mt-5">
                <img
                    src={getDefaultPhotoByGender(member?.Gender, member?.MemberId) ?? ""}
                    width="100"
                    height="100"
                    alt="profile-photo"
                />
                <div className="flex gap-1.5">
                    {isEditingMemberName &&
                        <input
                            type="text"
                            value={editedMemberName}
                            className="appearance-none focus:outline-none w-max"
                            onChange={(e) => setEditedMemberName(e.target.value)}
                            onKeyDown={handleKeyDownUpdateName}
                            autoFocus
                        />
                    }
                    {!isEditingMemberName &&
                        <p>{editedMemberName}</p>
                    }
                    <button
                        type="button"
                        onClick={handleEditNameButton}
                    >
                        <img
                            src={isEditingMemberName ? Check : (EditText ?? "")}
                            width="16"
                            height="16"
                            alt="edit-text"
                        />
                    </button>
                </div>
            </div>

            <div className="transparent-section mt-5">
                <h5 className="text-secondary">{strings.profile.profile_settings}</h5>

                {loading && <Loading classnames="mb-4" />}

                {!loading && (
                    <>
                        {+member.Status === MemberStatus.Active && (
                            <>
                                <div className="w-full max-w-[500px] mt-6 flex flex-col gap-3">
                                    <div className=" flex justify-between">
                                        <p className="text-[14px] font-bold">{strings.profile.dont_ask_personal_information}</p>
                                        <ToggleButton
                                            id="askPersonalInformations"
                                            checked={askPersonalInformations}
                                            onChange={(e) => {
                                                setAskPersonalInformations(e.target.checked);
                                            }}
                                        />
                                    </div>

                                    <div className="flex justify-between">
                                        <p className="text-[14px] font-bold">{strings.profile.dont_ask_how_was_your_day}</p>
                                        <ToggleButton
                                            id="askDailyQuestion"
                                            checked={askDailyQuestion}
                                            onChange={(e) => {
                                                setAskDailyQuestion(e.target.checked);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <p className="text-start font-bold text-[14px]">{strings.profile.lesson_method.title}</p>
                                    <hr className="w-full md:w-1/2 my-2"/>

                                    <div className="flex flex-col gap-y-1 gap-x-2 lg:items-start lg:flex-row">
                                        <div className="flex lg:w-1/2 items-center">
                                            <Radio
                                                id="lesson-method"
                                                title={strings.profile.lesson_method.title_one}
                                                state={!lessonMethod}
                                                action={handleLessonMethod}
                                            />
                                        </div>

                                        <div className="flex lg:w-1/2 items-center">
                                            <Radio
                                                id="lesson-method-2"
                                                title={strings.profile.lesson_method.title_two}
                                                state={lessonMethod}
                                                action={handleLessonMethod}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6">
                                    <p className="text-start font-bold text-[14px]">{strings.profile.feedback.title}</p>
                                    <hr className="w-full md:w-1/2 my-2"/>

                                    <div className="flex flex-col gap-y-1 gap-x-2 lg:items-start lg:flex-row">
                                        <div className="flex lg:w-1/2 items-center">
                                            <Radio
                                                id="feedback"
                                                title={strings.profile.feedback.title_one}
                                                state={!feedbackType}
                                                action={handleFeedbackType}
                                            />
                                        </div>

                                        <div className="flex lg:w-1/2 items-center">
                                            <Radio
                                                id="feedback-2"
                                                title={strings.profile.feedback.title_two}
                                                state={feedbackType}
                                                action={handleFeedbackType}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        <div className="mt-6">
                            <p className="text-start font-bold text-[14px]">{strings.profile.reason_to_learn_english.title}</p>
                            <hr className="w-full md:w-1/2 my-2"/>

                            <div className="flex flex-wrap gap-y-2">
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(reason => (
                                    <div key={reason} className="flex w-full lg:w-1/2 items-center">
                                        <Checkbox
                                            id={reason}
                                            type="checkbox"
                                            state={selectedLearnEnglishReasons.includes(reason)}
                                            action={() => handleReasonCheckboxChange(reason)}
                                        />
                                        <p
                                            className="text-[14px] min-w-max cursor-pointer"
                                            onClick={() => handleReasonCheckboxChange(reason)}
                                        >
                                            {strings.profile.reason_to_learn_english[`${String(reason)}`]}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="mt-6">
                            <p className="text-start font-bold text-[14px]">{strings.onboarding.first.title} <span
                                onClick={handleNavigateLevelAi}
                                className="!text-primary hover:!text-secondary underline cursor-pointer">({strings.ai_chatbot.level_test_ai})</span>
                            </p>
                            <hr className="w-full md:w-1/2 my-2"/>

                            <div className="flex flex-wrap gap-y-1">
                                {levelNames.map(level => (
                                    <div key={level.id} className="flex w-full lg:w-1/2 items-center">
                                        <Radio
                                            id={level.id}
                                            title={level.name}
                                            state={level.id === answer}
                                            action={() => setAnswer(level.id)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="w-full max-w-[500px] mt-6 flex flex-col gap-3">
                            <div className=" flex justify-between">
                                <p className="text-[14px] font-bold">
                                    {profileVisibility
                                        ? strings.profile.profile_visibility_open
                                        : strings.profile.profile_visibility_off
                                    }
                                </p>
                                <ToggleButton
                                    id="profileVisibility"
                                    checked={profileVisibility}
                                    onChange={updateMemberProfileStatus}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>

            {!loading &&
                <>
                    <div className="flex items-center justify-center mt-7">
                        <button
                            disabled={buttonLoading}
                            className={classNames("button w-[95%] xs:w-[330px] primary-button mb-2 focus:!bg-primary", {
                                "opacity-25 disabled:pointer-events-none": buttonLoading
                            })}
                            onClick={createOrUpdateDemandsAndReasons}
                        >
                            {buttonLoading
                                ? strings.general_information_messages.processing
                                : strings.profile.save_button
                            }
                        </button>
                    </div>

                    <div className="flex items-center justify-center mt-3">
                        <DeleteAccount
                            memberId={member?.MemberId}
                            token={token}
                        />   
                    </div>
                </>
            }

            <div className="left-margin-bottom"></div>

            {/* --- Modal --- */}
            <ModalOverlay ref={modalOverlayRef}/>
            <ModalOneButton
                ref={modalRef}
                title={strings.auth.information_messages.modal_title}
                message={modalText}
                overlayRef={modalOverlayRef}
                buttonText={strings.auth.form.okay_button}
                buttonClick={() => {
                    closeModal(modalOverlayRef, modalRef);
                }}
            />
        </div>
    )
}

export default ProfileSettings;