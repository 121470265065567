import React from 'react'
import { useLocalization } from '../../hooks/useLocalization';
import checkmark from "../../assets/animation/checkmark.json";
import Lottie from 'lottie-react';

const NpsContent = (props) => {

    const {criterionDetail, showSuccessMessage, successIconRef, commentErrorRef, model, setModel, action, uniqueValue} = props;
    
    const strings = useLocalization();

    // Tik işareti lottie animasyonu
    const defaultOptions = {
        autoplay: true,
        animationData: checkmark,
        loop: false,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className="flex justify-center items-center w-full">
            <div className="w-full">
                {showSuccessMessage ? (
                    <div className="flex flex-col items-center gap-2">
                        <Lottie 
                            animationData={defaultOptions.animationData}
                            loop={defaultOptions.loop}
                            autoplay={defaultOptions.autoplay}
                            style={{ height: 85, width: 85 }}
                        />
                        <p className="text-center text-[14px] mb-2">
                            {strings.popup.nps.success_message}
                        </p>
                    </div>
                ) : (
                    <>
                        <p className="text-center text-[14px] mb-2">
                            {criterionDetail.title}
                        </p>
                        <div className="flex flex-wrap justify-center items-center">
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                                <div key={index} className="flex">
                                    <input
                                        id={`${uniqueValue}_point_${item}`}
                                        value={item}
                                        type="radio"
                                        checked={item === model.rate}
                                        className="hidden"
                                        onChange={(e) => {
                                            setModel(model => ({
                                                ...model,
                                                rate: +e.target.value
                                            }))
                                        }}
                                    />
                                    <label htmlFor={`${uniqueValue}_point_${item}`} className="cursor-pointer m-[2px] sm:m-1">
                                        <span className={`${model.rate === item ? 'bg-primary' : 'bg-secondary'} text-white w-6 h-6 md:w-8 md:h-8 flex items-center justify-center font-semibold hover:bg-primary rounded-[10px]`}>
                                            {item}
                                        </span>
                                    </label>
                                </div>
                            ))}
                        </div>
                        {model.rate !== 0 && (
                            <div className="flex gap-1 items-center mt-3">
                                <div className="flex flex-col w-full">
                                    <textarea
                                        id="comment"
                                        rows="2"
                                        placeholder={strings.popup.nps.enter_your_comment}
                                        className="market-info-input max-w-full placeholder:pt-1 pt-1 resize-none max-h-16 mb-0"
                                        onChange={(e) => {
                                            setModel(model => ({
                                                ...model,
                                                comment: e.target.value
                                            }))
                                        }}
                                    ></textarea>

                                    <span ref={commentErrorRef} className="text-[11px] text-[#FF0000] ml-2 self-start"></span>

                                </div>
                                <button
                                    type="button"
                                    className="bg-primary hover:bg-secondary text-white font-semibold py-2 px-4 rounded-[10px]"
                                    onClick={action}
                                >
                                    {strings.support.send_request.send}
                                </button>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default NpsContent