import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Default_Video_Thumbnail } from "../../assets/img";
import { Loading, NoRecordsFound } from "../../components";
import { EventLogs } from "../../components/Constants";
import Pagination from "../../components/Pagination";
import useAnalytics from "../../hooks/useAnalytics";
import { useLocalization } from "../../hooks/useLocalization";
import { url } from "../../routes/utility";
import { GetAllVideosFilter, GetTagFilterList } from "../../services/VideoPractice/VideoPracticeService";
import { findTagIdsAndCategory, getLanguageIdByLanguageKey } from "../../utils";
import { DetailSearch } from "./components";

const Home = () => {

    const strings = useLocalization();
    const navigate = useNavigate();
    const language = useSelector(state => state.localizationStore.language);
    const analytics = useAnalytics();
    const localData = JSON.parse(localStorage.getItem("videoFilter")) || false;
    const pageLimit = 10;

    // Sayfa Yüklenmesi
    const [loading, setLoading] = useState(true);
    // Başlangıç verisini tutan state
    const [start, setStart] = useState(0);
    // İçerisinde bulunduğumuz sayfayı tutan state
    const [currentPage, setCurrentPage] = useState(1);
    // Listelenen veri sayısını tutan state
    const [filteredRecordsCount, setFilteredRecordsCount] = useState(0);
    // Toplam veri sayısını tutan state
    const [totalRecord, setTotalRecord] = useState(0);
    // Tüm verileri tutan state
    const [data, setData] = useState("");
    // Servise gitmeyi sağlayan state
    const [sendToEndpoint, setSendToEndpoint] = useState(true);
    // Tag verilerinin tutulduğu state
    const [tagData, setTagData] = useState([]);
    // Tag yüklenmesini kontrol eden state
    const [tagLoading, setTagLoading] = useState(true);
    // Filtreleme verilerini tutan fonksiyon
    const [detailSearch, setDetailSearch] = useState({
        tags: localData?.tags ||  [],
    });

    //Filtreleme alanı değişiklikleri bu alandan yönetiliyor
    const changeDetail = (values) => {
        setDetailSearch({ ...detailSearch, ...values });
    }

    const handleNavigate = (video) => {

        const logObject = {
            'seviye': '',
            'ilgi_alanlari': '',
            'video_name': ''
        };

        if(video.tags.length > 0) {
            /*
                * Seçili videonun tagları localStorage'a kaydediliyor
                * Bu işlemin nedeni diğer sayfalarda video taglarına göre event atılması
            */
            const tagObject = {};

            video.tags.forEach(item => {
                if (item.category.toLowerCase().includes("levels")) {
                    tagObject["seviye"] = item.name;
                } else if (item.category.toLowerCase().includes("interest")) {
                    if (!tagObject["ilgi_alanlari"]) {
                        tagObject["ilgi_alanlari"] = item.nameEnglish;
                    } else {
                        tagObject["ilgi_alanlari"] += ", " + item.nameEnglish;
                    }
                }
            });

            const videoInfo = {
              videoName: video?.title,
              videoTags: tagObject
            };

            logObject.seviye = tagObject.seviye;
            logObject.ilgi_alanlari = tagObject.ilgi_alanlari;
            logObject.video_name = video?.title;

            localStorage.setItem("videoInfo", JSON.stringify(videoInfo));
        }

        analytics.useAnalytics(EventLogs.VIDEO_EXERCISE_STARTED, logObject); //LOGGED
        navigate(url("videopractice.videoflow.video", { videoId: video.id }));
    }

    const getVideos = (language) => {

        const tagIdObject = findTagIdsAndCategory(detailSearch.tags, tagData);

        const model = {
            start: start,
            length: pageLimit,
            levelTagIds: tagIdObject.level,
            interestTagIds: tagIdObject.interest,
            languageTitleType: getLanguageIdByLanguageKey(language)
        }

        GetAllVideosFilter(model)
            .then(result => {
                if(result.status === 200) {
                    const resultContent = JSON.parse(result.content);

                    setTotalRecord(totalRecord => resultContent.recordsTotal);
                    setFilteredRecordsCount(filteredRecordsCount => resultContent.recordsFiltered);
                    setData(data => resultContent.data);
                }

                setSendToEndpoint(sendToEndpoint => false);
                setLoading(loading => false);
            })
            .catch(e => {})
    }

    useEffect(() => {
        GetTagFilterList(getLanguageIdByLanguageKey(language))
            .then(result => {
                if(result.status === 200) {
                    setTagData(JSON.parse(result.content));
                }

                setTagLoading(false);
            })
            .catch(e => {})
    }, [language])

    /*
        Sayfa yenilendiğinde filtre temizleniyor.
    */
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            localStorage.removeItem("videoFilter");
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        if(sendToEndpoint || language) {
            getVideos(language);
            setCurrentPage(1);
            setStart(0);
        }
    }, [sendToEndpoint, language])

    //sayfa değiştikçe bilgileri yeniden çağırıyoruz
    useEffect(() => {
        if (totalRecord !== 0) {
            getVideos(language);
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [start]);

    useEffect(() => {
        //LOGGED
        analytics.useAnalytics(EventLogs.VIDEO_EXERCISE_INDEX);
    }, []);

    return (
        <div className="main-content">
            <DetailSearch
                loading={loading}
                changeDetail={changeDetail}
                setSendToEndpoint={setSendToEndpoint}
                tagData={tagData}
                tagLoading={tagLoading}
            />

            <div className="transparent-section mt-5">
                {loading && <Loading classnames="mb-4" />}

                {!loading &&
                    <>
                        {data.length > 0 &&
                            data.map((item, index) => (
                                <button
                                    key={index}
                                    type="button"
                                    className="flex flex-col w-full gap-2 hover:bg-box p-2 rounded-md cursor-pointer"
                                    onClick={() => handleNavigate(item)}
                                >
                                    <div className="flex gap-3">
                                        <img
                                            src={item?.thumbnailUrl.length === 0
                                                ? Default_Video_Thumbnail
                                                : item?.thumbnailUrl
                                            }
                                            className="rounded-[10px] aspect-[4/3] w-[155px] max-h-max"
                                            alt="thumbnail"
                                        ></img>
                                        <div className="flex flex-col mt-1">
                                            <p className="font-bold text-start text-[14px] leading-4">{item?.title}</p>
                                            <div className="flex flex-col items-start gap-1">
                                                <div className="flex gap-1 min-w-max text-[12px]">
                                                    <span>{strings.video_practice.home.video_duration}:</span>{" "}
                                                    {item?.duration}
                                                </div>
                                                {item.tags.length > 0 &&
                                                    <div className="flex flex-wrap gap-1">
                                                        {item.tags.map((tag, index) => (
                                                                <p
                                                                    key={index}
                                                                    className={classNames("flex justify-center items-center rounded-[6px] text-[10px] px-1 py-0.5 min-w-max font-bold", {
                                                                        "bg-primary text-white" :
                                                                            tag.category.toLowerCase().includes('levels'),
                                                                        "bg-secondary text-white " :
                                                                            tag.category.toLowerCase().includes('interest'),
                                                                        "bg-[#f5f5f5] border border-onboarding-border text-base-text" :
                                                                            !tag.category.toLowerCase().includes('interest') &&
                                                                            !tag.category.toLowerCase().includes('levels')
                                                                    })}
                                                                >
                                                                    #{tag.name}
                                                                </p>
                                                            ))
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            ))
                        }

                        {data.length === 0 && <NoRecordsFound/>}
                    </>
                }
            </div>

            {filteredRecordsCount > 0 &&
                <p className="text-center mt-5 -mb-4">
                    {strings.video_practice.home.current_video_count
                        .replace("#total#", totalRecord)
                        .replace("#currentCount#", filteredRecordsCount)
                    }
                </p>
            }
            <Pagination
                totalCount={totalRecord}
                limit={pageLimit}
                start={start}
                setStart={setStart}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setLoading={setLoading}
                loadScreen={true}
            />

            <div className="left-margin-bottom"></div>
        </div>
    )
}

export default Home;