import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import checkmark from "../../assets/animation/checkmark.json";
import { Loading } from "../../components";
import { EventLogs, MemberLevelCollectionDynamicPoints, SpeakingLabResultType } from "../../components/Constants";
import useAuth from "../../hooks/useAuth";
import useMemberLevelCollection from "../../hooks/useMemberLevelCollection";
import usePageLeave from "../../hooks/usePageLeave";
import useTts from "../../hooks/useTts";
import { url } from "../../routes/utility";
import { AddMemberSpeakingLab, GetBookUnitExercise } from "../../services/SpeakingLab/SpeakingLabService";
import { SpeakingLabCongratulate, SpeakingLabFlow, SpeakingLabMotivationCard, SpeakingLabStart } from "./components";
import useAnalytics from "../../hooks/useAnalytics";

const SpeakingLab = () => {

    const { speakSentence, SpeakerComponent, isSpeakingComplete } = useTts();
    const { member } = useAuth();
    const navigate = useNavigate();
    const analytics = useAnalytics();
    const location = useLocation();
    const memberLevelCollectionId = useMemberLevelCollection(member.MemberId);
    
    const consecutiveExercisesRef = useRef(0);

    const { bookUnitId, selectedDate, selectedDateWithoutFormat } = location?.state || {};
    const dynamicPassingScore = MemberLevelCollectionDynamicPoints[+memberLevelCollectionId];
    const motivationCardExerciseSequence = 5;

    // Tik işareti lottie animasyonu
    const defaultOptions = {
        autoplay: true,
        animationData: checkmark,
        loop: false,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    // Başlangıç sayfasının gösterilip gösterilmeyeceğini kontrol eden state
    const [showStartPage, setShowStartPage] = useState(true);
    // Egzersiz verilerini tutan state
    const [exerciseData, setExerciseData] = useState({
        lastSequence: -1,
        exercises: []
    });
    // 5 Egzersizde bir çıkan motivasyon kartının gösterilip gösterilmeyeceğini kontrol eden state
    const [showMotivation, setShowMotivation] = useState(false);
    // İlk başlamada görünen butonun loading
    const [startExerciseButtonLoading, setStartExerciseButtonLoading] = useState(false);
    // Egzersiz servisinden gelen veriyi beklemek için loading
    const [exerciseServiceLoading, setExerciseServiceLoading] = useState(true);
    // Şu anki egzersizin indeksini tutan state
    const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0);
    // Kullanıcının aktif olarak seçtiği egzersizin indeksini tutan state
    const [activeExerciseIndex, setActiveExerciseIndex] = useState(0);
    // Egzersiz tamamlanma durumunu tutan state
    const [isExerciseCompleted, setIsExerciseCompleted] = useState(false);

    // Sayfadan ayrıldığında çağrılan fonksiyon
    const { setCallback } = usePageLeave(0 , true);

    const handleStartSpeakingLabExercise = async () => {
        setStartExerciseButtonLoading(true);
        const isSpeakingComplete = await speakSentence('', 'tr', 'empty');
        isSpeakingComplete && setShowStartPage(false);
        setStartExerciseButtonLoading(false);
    }

    // Bir egzersiz(sequence) tamamlandığında çağrılan fonksiyon
    const handleExerciseComplete = useCallback((completedIndex) => {
        consecutiveExercisesRef.current += 1;
        
        if (completedIndex + 1 >= exerciseData.exercises.length) {
            setIsExerciseCompleted(true);
        } else {
            setCurrentExerciseIndex(completedIndex + 1);
            setActiveExerciseIndex(completedIndex + 1);
            
            if (consecutiveExercisesRef.current === motivationCardExerciseSequence) {
                setShowMotivation(true);
                consecutiveExercisesRef.current = 0;
            }
        }
    }, [exerciseData.exercises.length, motivationCardExerciseSequence]);

    // Motivasyon kartındaki "Devam Et" butonuna tıklandığında çağrılan fonksiyon
    const handleContinueOnMotivationCard = useCallback(() => {
        setShowMotivation(false);
    }, []);

    // Kitap ünitesi ve üye bilgileri mevcut olduğunda, egzersiz verilerini çeker ve state'e kaydeder
    useEffect(() => {
        if(bookUnitId && member?.MemberId){
            localStorage.setItem("selectedDate", selectedDateWithoutFormat);

            const model = {
                bookUnitId: bookUnitId,
                dailyDate: selectedDate,
                memberId: member?.MemberId
            };

            GetBookUnitExercise(model)
                .then((result) => {
                    if(result.status === 200) {
                        const resultContent = JSON.parse(result.content);

                        // Eğer son sequence -1 değilse, currentExerciseIndex state'ini son sequence'in bir eksiği olarak günceller
                        if(resultContent.lastSequence !== -1) 
                            setCurrentExerciseIndex(resultContent.lastSequence - 1);
                        
                        setExerciseData({
                            lastSequence: resultContent.lastSequence || -1,
                            exercises: resultContent.exercises || []
                        });
                    }

                    setExerciseServiceLoading(false);
                })
                .catch();
        } else {
            navigate(url("speakinglesson.dailylesson"));
        }
    }, [bookUnitId, selectedDate]);

    // Kullanıcı sayfadan ayrıldığında çağrılan fonksiyon
    useEffect(() => {
        if(activeExerciseIndex !== 0 && !isExerciseCompleted) {
            const addMemberSpeakingLab = () => {
                AddMemberSpeakingLab({
                    memberId: member?.MemberId,
                    dailyDate: selectedDate,
                    bookUnitId: bookUnitId,
                    exerciseSequence: currentExerciseIndex + 1,
                    speakingLabResultType: SpeakingLabResultType.CONTINUE
                })
                .then()
                .catch();
            };

            setCallback(addMemberSpeakingLab);
        } else {
            setCallback(null);
        }
    }, [setCallback, activeExerciseIndex, isExerciseCompleted]);

    useEffect(() => {
        analytics.useAnalytics(EventLogs.SPEAKING_LAB_VIEWED);
    }, []);

    return (
        <div className="main-content">

            {exerciseServiceLoading && <Loading />}

            {!exerciseServiceLoading && 
                <>
                    {showStartPage &&
                        <SpeakingLabStart
                            clickAction={handleStartSpeakingLabExercise}
                            setCurrentExerciseIndex={setCurrentExerciseIndex}
                            exerciseData={{
                                memberId: member?.MemberId,
                                bookUnitId: bookUnitId,
                                selectedDate: selectedDate,
                                lastSequence: exerciseData?.lastSequence,
                                currentExerciseIndex: currentExerciseIndex
                            }}
                            loading={startExerciseButtonLoading}
                        />
                    }

                    {(!showStartPage && !showMotivation && !isExerciseCompleted) && (
                        <SpeakingLabFlow
                            key={currentExerciseIndex}
                            exercises={exerciseData.exercises}
                            speakSentence={speakSentence}
                            isSpeakingComplete={isSpeakingComplete}
                            dynamicPassingScore={dynamicPassingScore}
                            onExerciseComplete={handleExerciseComplete}
                            exerciseData={{
                                memberId: member?.MemberId,
                                bookUnitId: bookUnitId,
                                selectedDate: selectedDate,
                                lastSequence: exerciseData.lastSequence,
                                currentExerciseIndex: currentExerciseIndex
                            }}
                        />
                    )}

                    {(showMotivation && !isExerciseCompleted) && (
                        <SpeakingLabMotivationCard 
                            onContinue={handleContinueOnMotivationCard} 
                            defaultOptions={defaultOptions}
                        />
                    )}

                    {isExerciseCompleted && (
                        <SpeakingLabCongratulate 
                            defaultOptions={defaultOptions}
                            setActiveExerciseIndex={setActiveExerciseIndex}
                            exerciseData={{
                                memberId: member?.MemberId,
                                bookUnitId: bookUnitId,
                                selectedDate: selectedDate,
                                totalExerciseCount: exerciseData?.exercises?.length
                            }}
                        />
                    )}
                </>
            }
            <SpeakerComponent />

            <div className="left-margin-bottom"></div>
        </div>
    );
}

export default SpeakingLab;