import { useEffect } from "react";
import { EventLogs } from "../../../../components/Constants";
import useAnalytics from "../../../../hooks/useAnalytics";
import { useLocalization } from "../../../../hooks/useLocalization";
import Lottie from "lottie-react";

const SpeakingLabMotivationCard = ({ onContinue, defaultOptions }) => {

    const strings = useLocalization();
    const analytics = useAnalytics();

    useEffect(() => {
        analytics.useAnalytics(EventLogs.SPEAKING_LAB_FIVE_COMPLETED);
    }, []);

    return (
        <div className="flex flex-col items-center justify-center h-[60vh] max-md:mt-5">
            <div className="max-md:-mt-14">
                <Lottie 
                    animationData={defaultOptions.animationData}
                    loop={defaultOptions.loop}
                    autoplay={defaultOptions.autoplay}
                    style={{ height: 280, width: 280 }}
                />
            </div>
            <div className="flex flex-col items-center justify-between z-10 gap-16 md:gap-40">
                <p className="text-center font-bold max-w-[500px]">
                    {strings.speaking_lesson.speaking_lab.motivation_text.replace("#count#", 5)}
                </p>
                <button
                    type="button"
                    className="button w-[95%] xs:w-[330px] primary-button mb-2 mx-auto focus:!bg-primary"
                    onClick={() => {
                        onContinue();
                        analytics.useAnalytics(EventLogs.SPEAKING_LAB_CONTINUE_CLICKED, {
                            source: "motivation_card"
                        });
                    }}
                >
                    {strings.market.information.button}
                </button>
            </div>
        </div>
    );
}

export default SpeakingLabMotivationCard;