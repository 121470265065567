import React, {useEffect, useState} from "react";
import {MarketSourceTypes} from "../../../../components/Constants";
import {useLocalization} from "../../../../hooks/useLocalization";
import {formatPriceWithoutCurrency} from "../../../../utils";

const PackageContent = (props)  => {

    const { packageInfo, creditCardInfo } = props;

    const strings = useLocalization();
    const currencySymbol = MarketSourceTypes.SPEAKING_LESSON === packageInfo?.source
        ? packageInfo?.selectedProduct?.productDetail?.priceDetail?.currencySymbol
        : packageInfo?.selectedProduct?.additionalProductPriceDetails?.currencySymbol

    // Taksit indirimlerini tutmak için kullanılan state
    const [installmentDiscounts, setInstallmentDiscounts] = useState([]);

    useEffect(() => {
        if(creditCardInfo?.priceInfo?.discountDetails?.length > 0){
            let tempInstallmentDiscounts = [];

            creditCardInfo?.priceInfo?.discountDetails
                .map((discount) => {
                    tempInstallmentDiscounts.push({
                       title: discount.name,
                       rate: discount.discountRate
                    });
                });

            setInstallmentDiscounts(tempInstallmentDiscounts);
        } else {
            setInstallmentDiscounts([]);
        }
    }, [creditCardInfo?.priceInfo?.discountDetails]);

    return (
        <>
            {/* İndirim listesi ve toplam fiyat */}
            <div className="flex flex-col max-w-max mx-auto text-lg mt-2">
                {packageInfo?.discountInformation?.discounts?.length > 0 &&
                    packageInfo?.discountInformation?.discounts.map((discount, index) => (
                        <div key={index} className="flex text-[13px] justify-between w-full">
                            <p>
                            {discount.title.includes("Reklam") || discount.title.includes("Diğer Otomatik İndirim") 
                                ? strings.market.speakingLesson.auto_create_campaign.discount_for_you
                                : discount.title
                            }
                            </p>
                            <p>% {discount.rate}</p>
                        </div>
                    ))
                }

                {installmentDiscounts.length > 0 &&
                    installmentDiscounts.map((discount, index) => (
                        <div key={index} className="flex text-[13px] justify-between w-full">
                            <p>{discount.title}</p>
                            <p>% {discount.rate}</p>
                        </div>
                    ))
                }

                <p className="text-base-text font-bold w-full">
                    <span className="text-primary text-[16px]">{strings.market.checkout.total_amount}:{" "}</span>
                    {packageInfo?.source === MarketSourceTypes.SPEAKING_LESSON
                        ? creditCardInfo?.bank.id === -1
                            ? packageInfo?.discountInformation?.totalPrice === 0
                                ? packageInfo?.selectedProduct?.productDetail?.priceDetail?.totalPrice
                                : formatPriceWithoutCurrency(packageInfo?.discountInformation?.totalPrice)
                            : creditCardInfo.priceInfo?.price
                        : packageInfo?.selectedProduct?.additionalProductPriceDetails?.totalPrice
                    }
                    {currencySymbol}
                </p>
            </div>

            {/* Seçili paket ve yan hak bilgileri */}
            <p className="text-sm mx-auto mt-5 text-center">
                <strong className="text-primary text-base">{strings.market.speakingLesson.selected_package.title}</strong>
            </p>
            <div className="text-sm mx-auto mt-1 text-center">
                {MarketSourceTypes.SPEAKING_LESSON === packageInfo?.source && (
                    <>
                        <strong className="text-base-text text-base">
                            {packageInfo?.selectedProduct?.title}{" "}
                            {packageInfo?.selectedProduct?.giftMonth > 0 && " + " +
                                packageInfo?.selectedProduct?.giftMonth + strings.market.speakingLesson.selected_package.gift_month}{" "}
                            {strings.market.checkout.for_package}
                        </strong>

                        <p className="text-base-text text-base font-bold mt-3">
                            {packageInfo?.discountInformation?.giftLessonCount !== 0 &&
                                strings.market.speakingLesson.total_lesson_price_with_gift_no_tax
                                    .replace("#totalLessonCount#", packageInfo?.discountInformation?.totalLessonCount)
                                    .replace("#giftLessonCount#", packageInfo?.discountInformation?.giftLessonCount)
                            }
                            {packageInfo?.discountInformation?.giftLessonCount === 0 &&
                                strings.market.speakingLesson.total_lesson_price_no_tax
                                    .replace("#totalLessonCount#", packageInfo?.discountInformation?.totalLessonCount)
                            }
                        </p>

                        <div className="flex flex-col gap-0.5 text-base-text text-base font-bold mt-3">
                            <span>{strings.market.speakingLesson.selected_package.extra_rights_one}</span>
                            <span>{strings.market.speakingLesson.selected_package.extra_rights_two}</span>
                            <span>{strings.market.speakingLesson.selected_package.extra_rights_three}</span>
                        </div>
                    </>
                )}
                {MarketSourceTypes.POSTPONE_RIGHT === packageInfo?.source &&
                    <strong className="text-base-text text-base">
                        {strings.market.additional_rights.postpone_right_checkout
                            .replace("#count#", packageInfo?.selectedProduct?.rightCount)
                        }
                    </strong>
                }
            </div>
            {MarketSourceTypes.SPEAKING_LESSON === packageInfo?.source &&
                <p className="text-sm mx-auto mt-3 text-center">
                    {packageInfo?.selectedProduct?.productDetail?.postponeRightCount}{strings.market.speakingLesson.selected_package.postpone_right}<br />
                    {packageInfo?.selectedProduct?.productDetail?.teacherHourChangeRightCount}{strings.market.speakingLesson.selected_package.change_teacher_and_time_right}<br />
                    {packageInfo?.selectedProduct?.productDetail?.missedRightCount}{strings.market.speakingLesson.selected_package.missed_call_right}<br />
                    {packageInfo?.selectedProduct?.productDetail?.freezeRightCount > 0 && strings.market.speakingLesson.selected_package.freeze_lesson_right}
                </p>
            }
        </>
    )
}

export default PackageContent;