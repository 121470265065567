import classNames from "classnames";
import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import { Loading, SentenceBookmarkButton } from "../../../../components";
import { EventLogs, ExerciseSubCode, PronunciationErrors, PronunciationResultType } from "../../../../components/Constants";
import useAuth from "../../../../hooks/useAuth";
import { useLocalization } from "../../../../hooks/useLocalization";
import useSpeechStudio from "../../../../hooks/useSpeechStudio";
import useWindowSize from "../../../../hooks/useWindowSize";
import { ScoreCard } from "../../../Exercises/components";
import { SpeakingLabCorrect, SpeakingLabPlaybackOptions, SpeakingLabWrong } from "./components";
import { removePunctuation } from "../../../../utils";
import useAnalytics from "../../../../hooks/useAnalytics";

const SpeakingLabFillInTheBlank = ({ exerciseData, onNext, speakSentence, isSpeakingComplete, dynamicPassingScore, ttsInfo, defaultOptions }) => {

    const strings = useLocalization();
    const { member } = useAuth();
    const {
        handleSpeechStudioRecording,
        handleResetStates,
        handleResetIsFirstRecordingCompleted,
        showAnimation,
        isFirstRecordingCompleted,
        speechStudioLoading,
        speechStudioResult,
        isErrorOccured,
        audioKey
    } = useSpeechStudio({
        contentId: exerciseData?.exercise?.sentenceId,
        memberId: member?.MemberId,
        source: PronunciationResultType.SPEAKING_LAB
    });
    const windowSize = useWindowSize();
    const analytics = useAnalytics();

    const isMobile = windowSize?.width < 640;

    const pronunceSentence = exerciseData?.exercise?.exerciseSubCode === ExerciseSubCode.QUESTION
        ? exerciseData?.exercise?.answer
        : exerciseData?.exercise?.sentence;

    // Yeniden seslendir butonunun sayfaya ilk girildiğinde görüntülenmemesini sağlayan state.
    const [isDisablePronunceAgainButton, setIsDisablePronunceAgainButton] = useState(false);
    // Doğru veya yanlış cevap durumunu tutan state
    const [isCorrect, setIsCorrect] = useState(false);
    // Cümleyi görüntülemek için kullanılan state
    const [displaySentence, setDisplaySentence] = useState('');
    // Kullanıcı bir defa yanlış cevap verdiğinde, bu state true olur.
    // Amacımız bir kere yanlış cevap verildiğinde cümlenin tamamını kullanıcıya göstermektir.
    const [isWrongAnswer, setIsWrongAnswer] = useState(false);
    // Yanlış cevap sayısını tutan yeni state
    const [wrongAttempts, setWrongAttempts] = useState(0);

    // Yanlış cevap componentine gönderildiğinde cümleyi tekrar seslendirmek için kullanılır.
    const handlePronunciation = async (sentence) => {
        window.scrollTo(0, 0);
        handleSpeechStudioRecording(removePunctuation(sentence), member?.MemberId);
        handleResetStates();
        resetDisplaySentence();
        analytics.useAnalytics(EventLogs.SPEAKING_LAB_PRONOUNCE_CLICKED);
    }

    // Cümleyi tekrar seslendirmek için kullanılır.
    const handleTryAgain = async () => {
        resetDisplaySentence();
        handleResetIsFirstRecordingCompleted();
        handleResetStates();
        window.scrollTo(0, 0);
        await speakSentence(ttsInfo.sentence, ttsInfo.language, ttsInfo.textId);

        analytics.useAnalytics(EventLogs.SPEAKING_LAB_TRY_AGAIN_CLICKED,{
            source: "fill_in_the_blanks"
        });
    }

    // Egzersize devam etmek için kullanılır.
    const handleContinue = () => {
        handleResetStates();
        handleResetIsFirstRecordingCompleted();
        onNext();
        analytics.useAnalytics(EventLogs.SPEAKING_LAB_CONTINUE_CLICKED, {
            source: "fill_in_the_blanks"
        });
    }
    
    /**
     * Bu fonksiyon, doldurulması gereken boşlukları içeren bir cümleyi işler ve görüntülenmek üzere formatlar.
     * Eğer kullanıcı yanlış cevap verdiyse, boşlukların yerine doğru kelimeleri koyar.
     * Aksi takdirde, boşlukları belirli bir stil ile gösterir.
     */
    const resetDisplaySentence = () => {
        if (exerciseData.exercise?.fillInTheBlank) {
            const words = exerciseData.exercise.fillInTheBlank.split(' ');
            const pronounceWords = pronunceSentence.split(' ');

            const formattedWords = words.map((word, index) => {
                if (word.match(/#.*#/)) {
                    if (isWrongAnswer) {
                        const actualWord = pronounceWords[index];
                        return `<span class="text-onboarding-border">${actualWord}</span>`;
                    } else {
                        return '<span class="inline-flex items-center justify-center w-16 h-8 bg-onboarding-bg rounded-[10px] align-text-bottom mx-1 -my-1"></span>';
                    }
                }
                return word;
            });
            setDisplaySentence(formattedWords.join(' '));
        }
    };

    // Bu useEffect hook'u, exerciseData veya speakSentence değiştiğinde çalışır.
    // Eğer exerciseData.exercise içinde bir cümle varsa, bu cümleyi seslendirir.
    // Bu, kullanıcıya egzersiz cümlesini otomatik olarak dinletmek için kullanılır.
    useEffect(() => {
        const speakExerciseSentence = async () => {
            await speakSentence(ttsInfo.sentence, ttsInfo.language, ttsInfo.textId);
        };

        if (Object.keys(ttsInfo).length > 0) speakExerciseSentence();
    }, []);

    // 'Tekrar Seslendir' butonunun sayfaya ilk seslendirme bittikten sonra hep görüntülenmesi sağlanıyor.
    // Kullanıcı 'Tekrar Seslendir' butonuna, tekrar tıklarsa seslendirme esnasında disable özelliği kullanıyor.
    // Not: Disable durumu buton üzerinden kontrol edilmektedir.
    useEffect(() => {
        if(isSpeakingComplete && !isDisablePronunceAgainButton)
            setIsDisablePronunceAgainButton(true);
    }, [isSpeakingComplete, isDisablePronunceAgainButton]);

    
    // Bu kod, `speechStudioResult` ve `speechStudioLoading` değişkenlerini izler.
    // Eğer `speechStudioResult` varsa ve `speechStudioLoading` false ise
    // sayfayı 200 piksel aşağı kaydırır.
    useEffect(() => {
        if (speechStudioResult && !speechStudioLoading) {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth'
            });
        }
    }, [speechStudioResult, speechStudioLoading]);

    /*
        Bu kod, `speechStudioResult` ve `speechStudioLoading` değişkenlerini izler.
        Eğer `speechStudioResult` varsa ve `speechStudioLoading` false ise
        `isCorrect` değişkenini `speechStudioResult.Score` değişkeni ile `dynamicPassingScore` değişkenini karşılaştırarak günceller.
    */
    useEffect(() => {
        const playFeedbackSound = async (isCorrect) => {
            isCorrect
                ? await speakSentence('', '', 'correct-answer')
                : await speakSentence('', '', 'wrong-answer');
        };

        if (speechStudioResult && !speechStudioLoading) {
            const cleanWord = (word) => word.replace(/[.,!?;:'"()\-]/g, '').toLowerCase().trim();
    
            const compareWith = exerciseData.exercise.exerciseSubCode === ExerciseSubCode.QUESTION 
                ? exerciseData.exercise.answer.split(/\s+/)
                : exerciseData.exercise.sentence.split(/\s+/);
    
            const hasErrors = speechStudioResult.words.some((word, index) => {
                const correctWord = compareWith[index];
                return cleanWord(word.word) !== cleanWord(correctWord) ||
                        word.error_type === PronunciationErrors.MISPRONUNCIATION || 
                        word.error_type === PronunciationErrors.OMISSION;
            });
    
            const passedScore = speechStudioResult.pron_score >= dynamicPassingScore;
            const isCorrect = !hasErrors && passedScore;
    
            playFeedbackSound(isCorrect);
            setIsCorrect(isCorrect);

            // Eğer cevap yanlışsa, wrongAttempts'i artır
            if (!isCorrect) {
                setWrongAttempts(prevAttempts => prevAttempts + 1);
            }

            // Kullanıcı bir kez yanlış cevap verdiğinde, cümleyi kullanıcıya göstermek için set edilir.
            !isWrongAnswer && setIsWrongAnswer(!isCorrect);
    
            const words = exerciseData.exercise.fillInTheBlank.split(/\s+/);
    
            const coloredWords = words.map((word, index) => {
                try {
                    if (word.includes('#word#')) {
                        const correctWord = compareWith[index];
                        const resultWord = speechStudioResult.words.find(w => 
                            cleanWord(w.word) === cleanWord(correctWord)
                        );
        
                        let className = "text-green";
                        if (resultWord) {
                            if (resultWord.error_type === PronunciationErrors.OMISSION) {
                                className = "text-red";
                            } else if (resultWord.error_type === PronunciationErrors.MISPRONUNCIATION) {
                                className = "text-secondary";
                            }
                        } else {
                            className = "text-red";
                        }
        
                        const displayWord = resultWord ? resultWord.word : correctWord;
                        return `<span class="${className}">${displayWord}</span>`;
                    }
                    return word;
                } catch (error) {
                    return [];
                }
            });
    
            setDisplaySentence(coloredWords.join(' '));
        }
    }, [speechStudioResult, speechStudioLoading, dynamicPassingScore, exerciseData.exercise, isWrongAnswer]);

    // Bu use effect her cümle değiştiğinde, görünen cümle içerisini sıfırlar.
    useEffect(() => {
        resetDisplaySentence();
    }, [exerciseData.exercise?.fillInTheBlank]);

    useEffect(() => {
        analytics.useAnalytics(EventLogs.SPEAKING_LAB_FILL_BLANK_IMPRESSION);
    }, [])

    return (
        <>
           <p className="my-5 text-center">
              {strings.speaking_lesson.speaking_lab.fill_in_the_blank_title}
           </p>
           <div className="gray-section min-h-[300px] flex flex-col">
                <div className="w-full flex justify-end">
                    <SentenceBookmarkButton
                        initialIsSaved={exerciseData?.exercise?.isSavedSentence} 
                        sentenceId={exerciseData?.exercise?.sentenceId} 
                        logEvent={{
                            true: EventLogs.SPEAKING_LAB_ADDED_TO_LEARNING_LIST,
                            false: EventLogs.SPEAKING_LAB_REMOVED_TO_LEARNING_LIST
                        }}
                    />
                </div>
                <div className="flex flex-col gap-2 justify-start">
                    <p className="text-center font-bold text-[20px]" dangerouslySetInnerHTML={{ __html: displaySentence }}></p>
                </div>

                <div className="flex-grow"></div>

                <div className="flex flex-col gap-3">
                    {exerciseData?.exercise?.hint ?
                        <p>
                            <span className="font-bold">{strings.speaking_lesson.speaking_lab.sentence_hint}:{" "}</span>
                            {exerciseData?.exercise?.hint}
                        </p>
                        : <p className="text-[14px]">
                            {exerciseData?.exercise?.sentenceTranslation}
                        </p>
                    }
                    {(isFirstRecordingCompleted && !showAnimation) &&
                        <SpeakingLabPlaybackOptions 
                            systemPronunceButtonClick={() => {
                                if(exerciseData?.exercise?.exerciseSubCode === ExerciseSubCode.QUESTION)
                                    speakSentence(exerciseData?.exercise?.answer, process.env.REACT_APP_LANG_EN_CODE, exerciseData?.exercise?.answerId);
                                else
                                    speakSentence(exerciseData?.exercise?.sentence, process.env.REACT_APP_LANG_EN_CODE, exerciseData?.exercise?.sentenceId);
                            }}
                            memberAudioUrl={speechStudioResult?.audioUrl}
                            audioKey={audioKey}
                        />
                    }
                    <div className="flex gap-2 justify-center items-center mb-2">
                        <div className="relative group">
                            <button
                                type="button"
                                className={classNames("flex items-center justify-center h-14 w-14 bg-nav-button-bg rounded-full hover:bg-nav-button-bg/70", {
                                    "opacity-70 pointer-events-none" : !isSpeakingComplete || showAnimation || !isDisablePronunceAgainButton,
                                })}
                                disabled={!isSpeakingComplete || showAnimation || !isDisablePronunceAgainButton}
                                onClick={() => {
                                    speakSentence(ttsInfo.sentence, ttsInfo.language, ttsInfo.textId);
                                    analytics.useAnalytics(EventLogs.SPEAKING_LAB_VOICE_AGAIN_CLICKED);
                                }}
                            >
                                <span className="material-symbols-outlined text-base-text text-[26px]">replay</span>
                            </button>
                            {(isSpeakingComplete && !showAnimation) &&
                                <div className="tooltip-top -top-[38px]">
                                    {strings.speaking_lesson.speaking_lab.voice_again}
                                </div>
                            }
                        </div>
                        <div className="relative group">
                            <button
                                type="button"
                                className={classNames("flex items-center justify-center h-14 w-14 bg-nav-button-bg rounded-full hover:bg-nav-button-bg/70", {
                                    "opacity-70 pointer-events-none" : speechStudioLoading || !isSpeakingComplete,
                                })}
                                disabled={speechStudioLoading || !isSpeakingComplete}
                                onClick={() => handlePronunciation(pronunceSentence)}
                            >
                                <span className="material-symbols-outlined text-base-text text-[28px]">mic</span>
                                {showAnimation ? (
                                    <div className="absolute inset-0 rounded-full overflow-hidden  animate-overlay">
                                        <Lottie 
                                            animationData={defaultOptions.animationData} 
                                            loop={true}
                                            autoplay={true}
                                            style={{ height: 56, width: 56 }}
                                        />
                                    </div>
                                ) : null}
                            </button>
                            {isSpeakingComplete && 
                                <div className="tooltip-top -top-[38px]">
                                    {strings.video_practice.word_card.word_pronunciation}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {speechStudioLoading && <Loading text={strings.speaking_lesson.speaking_lab.results_loading} />}

            {speechStudioResult && !speechStudioLoading &&
                <div className="flex items-center justify-center">
                    <ScoreCard result={speechStudioResult} classnames="mt-5 max-w-[500px]"/>
                </div>
            }

            {isErrorOccured && 
                <SpeakingLabWrong 
                    textInfoTitle={strings.speaking_lesson.speaking_lab.pronunce_error_text}
                    buttonClick={handleTryAgain} 
                />
            }

            {(speechStudioResult && !speechStudioLoading && !isErrorOccured) && (

                <>
                    {isMobile && <div className="h-[180px]"></div>}
                    {isCorrect 
                        ? <SpeakingLabCorrect
                            textInfo={speechStudioResult?.words} 
                            buttonClick={handleContinue}
                          />
                        : <SpeakingLabWrong
                            textInfo={speechStudioResult?.words} 
                            buttonClick={handleTryAgain}
                            skipButtonCondition={wrongAttempts >= 3}
                            skipButtonClick={onNext}
                          />
                    }
                </>
            )}
        </>
    )
}

export default SpeakingLabFillInTheBlank;