import React, {useEffect, useRef, useState} from "react";
import {Accept, Multiply} from "../../../../../../assets/img";
import {Speaker} from "../../../../../../assets/svg";
import {Accordion, Loading, Modal, ModalOverlay} from "../../../../../../components";
import {LessonResultAccordionType} from "../../../../../../components/Constants";
import useAccordionActivity from "../../../../../../hooks/useAccordionActivity";
import {useLocalization} from "../../../../../../hooks/useLocalization";
import useSpeaker from "../../../../../../hooks/useSpeaker";
import {GetAndUpdateLessonCorrectionsService} from "../../../../../../services/SpeakingLesson/SpeakingLessonService";
import {closeModal, openModal} from "../../../../../../utils";

const AttendedInformation = ({ data }) => {

    const strings = useLocalization();
    const { speakSentence, SpeakerComponent } = useSpeaker();
    const { activities, addAccordionActivity } = useAccordionActivity(
        data?.lessonResultId,
        data?.lessonResultAccordionActivities
    );

    const modalRef = useRef();
    const modalOverlayRef = useRef();

    const [modalMessage, setModalMessage] = useState("");
    const [loading, setLoading] = useState(true);

    const [mistakeData, setMistakeData] = useState([]);
    const [correctData, setCorrectData] = useState([]);
    const [correctionData, setCorrectionData] = useState([]);
    const [response, setResponse] = useState([]);
    const [isResponseError, setIsResponseError] = useState(false);

    //Puanlamaların başlıklarını index değerine göre getiren fonksiyon.
    const getStatisticsTitle = (title) => {
        if (title === "Speaking Grammar Usage") {
            return strings.speaking_lesson.daily_lesson.speaking_grammar_usage;
        } else if (title === "Speaking Vocabulary Usage") {
            return strings.speaking_lesson.daily_lesson.speaking_vocabulary_usage;
        } else if (title === "Speaking Pronunciation") {
            return strings.speaking_lesson.daily_lesson.speaking_pronunciation;
        } else if (title === "Listening Comprehension") {
            return strings.speaking_lesson.daily_lesson.listening_comprehension;
        } else if (title === "Reading Vocabulary Awareness") {
            return strings.speaking_lesson.daily_lesson.reading_vocabulary_awareness;
        } else if (title === "Reading Pronunciation") {
            return strings.speaking_lesson.daily_lesson.reading_pronunciation;
        }
    };

    //Öğrencinin ders notları 'sayı' şeklinde geldiğinde alan üzerine hover olunduğunda ders notu açıklaması,
    //için modal açan fonksiyon.
    const handleLetterInfoClick = (letterNote, index) => {
        letterNote = parseInt(letterNote) > 7 ? parseInt(letterNote) : parseInt(letterNote) + 1
        let message = getMessage(letterNote, index);
        setModalMessage(message)

        openModal(modalOverlayRef, modalRef)
    };

    //Öğrencinin ders notlarına göre modal içerisindeki açıklamaları dolduran fonksiyon.
    const getMessage = (letterNote, index) => {
        const notes = ["one", "two_three", "four_five", "six_seven", "eight_nine", "ten"];
        const lessonInfo = strings.speaking_lesson.letter_note_informations;
        const lessonKeys = Object.keys(lessonInfo);

        if (index < 0 || index >= lessonKeys.length) return;

        const lessonKey = lessonKeys[index];
        const noteIndex = letterNote === 1 ? 0
            : letterNote === 2 || letterNote === 3 ? 1
                : letterNote === 4 || letterNote === 5 ? 2
                    : letterNote === 6 || letterNote === 7 ? 3
                        : letterNote === 8 || letterNote === 9 ? 4
                            : 5;

        return lessonInfo[lessonKey][notes[noteIndex]];
    };

    //Hatalar servise gönderilir.
    //Servis hataları GPT Api'a gönderir ve düzeltir. Daha sonra veritabanına kaydeder.
    //Servis bize hataları ve düzeltilmiş halini döndürür. Veritabanına kaydedilmesine rağmen sayfa yenileme ihtiyacı duymamak için servisten dönen cevabı...
    //...ilk aşamada ekrana bastırıyoruz.
    const getAndUpdateLessonCorrections = async (lessonResultId, mistakeString) => {

        const result = await GetAndUpdateLessonCorrectionsService(lessonResultId, mistakeString);

        if (result.statusCode === 200) {
            let resultContent = JSON.parse(result.message);

            if (resultContent.length > 0) {
                setResponse(response => resultContent);
            } else {
                setIsResponseError(isResponseError => true);
            }
        }
        setLoading(loading => false);
    }

    const capitalizeFirstLetter = (str) => {
        return str.length === 0 ? str : str.charAt(0).toUpperCase() + str.slice(1);
    }

    //Gelen verilerdeki 'yanlış telaffuz edilen' cümleleri ve 'doğru telaffuz edilen' cümleleri ayırır.
    useEffect(() => {
        let tmpMistakeData = [];
        let tmpCorrectData = [];

        if (data?.corrections.length > 0) {
            tmpMistakeData = data?.corrections.filter(item => item.mistake !== "");
            tmpCorrectData = data?.corrections.filter(item => item.mistake === "");
        }

        setMistakeData(mistakeData => tmpMistakeData);
        setCorrectData(correctData => tmpCorrectData);
    }, [data]);

    useEffect(() => {
        //Eğer öğrencinin hatalı cümlesi var ise GPT Api'ya göndermek için 'mistakeString' oluşturuluyor.
        if (mistakeData.length > 0) {

            let mistakeString = "";

            //Sadece correction kısmı boş olan veriler filtreleniyor.
            const filteredMistakeData = mistakeData.filter(item => item.correction === "");

            //Filtrelen verilen içerisindeki hatalı cümleler '#' ile birleştirilerek tek bir string haline getiriliyor.
            filteredMistakeData.map(({ mistake }, index) => {
                mistakeString += filteredMistakeData.length - 1 !== index ? mistake + "#" : mistake
            })

            //Oluşturulan string GPT Api'ya gönderiliyor.
            //Verileri filtrelememiz sayesinde kullanıcının 1 tane bile boş correction'u olsa bile onu da düzelttiriyoruz.
            filteredMistakeData.length > 0 ? getAndUpdateLessonCorrections(data?.lessonResultId, mistakeString) : setLoading(loading => false);
        }
    }, [mistakeData])

    useEffect(() => {
        if (mistakeData.length > 0) {
            let tmpMistakeData = mistakeData.filter(m => !(m.correction.length === 0 && m.mistake.length > 0));
            let tmpResponse = [...tmpMistakeData, ...response];
            setCorrectionData(mistakeData => tmpResponse);
        }
    }, [mistakeData, response]);

    useEffect(() => {
        setResponse(response => []);
        setLoading(loading => true);
    }, [data?.corrections]);

    return (
        <>
            <Accordion
                title={strings.speaking_lesson.daily_lesson.teacher_ranking_and_evaluation}
                disableOutsideClick={true}
                isScrollable={false}
                isDownArrowIcon={false}
                openCloseCheckboxStatus={
                    activities
                        ?.find(l => LessonResultAccordionType.LESSON_REMARKS_AND_REVIEWS === l.lessonResultAccordionType)?.isOpened
                    || false
                }
                action={() => addAccordionActivity(LessonResultAccordionType.LESSON_REMARKS_AND_REVIEWS)}
            >
                {/* Ders Puanı Akordiyon */}
                <Accordion
                    title={strings.speaking_lesson.daily_lesson.lesson_ranking}
                    contentClassNames="!bg-white"
                    disableOutsideClick={true}
                    isScrollable={false}
                    isDownArrowIcon={false}
                    isOpenCloseIcon={false}
                    openCloseCheckboxStatus={
                        activities
                            ?.find(l => LessonResultAccordionType.LESSON_POINT === l.lessonResultAccordionType)?.isOpened
                        || false
                    }
                    action={() => addAccordionActivity(LessonResultAccordionType.LESSON_POINT)}
                >
                    <div className="flex flex-col sm:flex-row text-sm py-3">
                        <div className="w-full">
                            <div className=" mx-auto flex flex-wrap justify-center gap-y-5 gap-x-1">
                                {data &&
                                    data.lessonNotes.map(({letterNote, title}, index) => (
                                        <div key={index}
                                             className="font-bold w-[97px] flex flex-col justify-end text-center">
                                            <p className="mb-2">{getStatisticsTitle(title)}</p>
                                            <div className="letter-note cursor-pointer relative group ">
                                                {letterNote === "" || letterNote === '0' ? (
                                                    <span>-</span>
                                                ) : isNaN(letterNote) ? (
                                                    <span className="text-primary text-[30px]">{letterNote}</span>
                                                ) : (
                                                    <div
                                                        className="align-bottom"
                                                        onClick={() => handleLetterInfoClick(letterNote, index)}
                                                    >
                                                        <span
                                                            className="text-primary group-hover:text-secondary text-[18px]">{parseInt(letterNote) > 7 ? parseInt(letterNote) : parseInt(letterNote) + 1}</span>
                                                        <span
                                                            className="text-primary group-hover:text-secondary">/10</span>
                                                        <div className="tooltip-top -top-[38px] font-normal">
                                                            <p>{strings.speaking_lesson.click_for_lesson_detail}</p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </Accordion>

                {/* Eğitmen Yorumları */}
                <Accordion
                    title={strings.speaking_lesson.daily_lesson.teacher_comments}
                    contentClassNames="!bg-white"
                    disableOutsideClick={true}
                    isScrollable={false}
                    isDownArrowIcon={false}
                    isOpenCloseIcon={false}
                    openCloseCheckboxStatus={
                        activities
                            ?.find(l => LessonResultAccordionType.TEACHER_COMMENTS === l.lessonResultAccordionType)?.isOpened
                        || false
                    }
                    action={() => addAccordionActivity(LessonResultAccordionType.TEACHER_COMMENTS)}
                >
                    <div className="py-3 mx-auto text-start text-sm flex flex-col lg:flex-row">
                        <div>
                            <div className="font-bold max-w-[95%] relative pb-2 text-secondary">
                                {strings.speaking_lesson.daily_lesson.teacher_comment}
                                <div className="absolute bottom-0 left-0 right-0 h-[3px] bg-primary rounded"></div>
                            </div>
                            <p dangerouslySetInnerHTML={{__html: data?.remark?.replace(/\\n/g, '<br />')}}/>
                        </div>
                    </div>
                </Accordion>

                {/* Eğitmen Önerileri */}
                <Accordion
                    title={strings.speaking_lesson.daily_lesson.teacher_recommendations}
                    contentClassNames="!bg-white"
                    disableOutsideClick={true}
                    isScrollable={false}
                    isDownArrowIcon={false}
                    isOpenCloseIcon={false}
                    openCloseCheckboxStatus={
                        activities
                            ?.find(l => LessonResultAccordionType.TEACHER_RECOMMENDATIONS === l.lessonResultAccordionType)?.isOpened
                            || false
                    }
                    action={() => addAccordionActivity(LessonResultAccordionType.TEACHER_RECOMMENDATIONS)}
                >
                    <div className="py-3 mx-auto text-start text-sm flex flex-col lg:flex-row">
                        <div className="w-full">
                            <div
                                className="font-bold max-w-[95%] lg:max-w-full relative pb-2 text-sm mt-4 lg:mt-0 text-secondary">
                                {strings.speaking_lesson.daily_lesson.teacher_suggestions}
                                <div className="absolute bottom-0 left-0 right-0 h-[3px] bg-primary rounded"></div>
                            </div>
                            {loading ? (
                                <Loading/>
                            ) : (
                                <>
                                    {isResponseError ? (
                                        <div className="flex gap-2 items-center justify-center mt-5">
                                            <p>{strings.general_information_messages.error500_title}</p>
                                        </div>
                                    ) : (
                                        <>
                                            {Object.keys(correctionData).length > 0 && (
                                                <p className="font-bold mb-2 mt-4 px-2 bg-[#ededed] rounded-[3px]">{strings.speaking_lesson.daily_lesson.mispronunciation}</p>
                                            )}
                                            {
                                                correctionData.length > 0 && (
                                                    correctionData.map(({mistake, correction}, index) => (
                                                        <div key={index} className="mb-2">
                                                            <div className="flex gap-2 items-center">
                                                                <img src={Multiply} className="w-3 h-3" alt="multiply"/>
                                                                <p>{capitalizeFirstLetter(mistake)}</p>
                                                            </div>
                                                            <div
                                                                className="flex gap-2 items-center max-w-max hover:text-base-text/70 cursor-pointer"
                                                                onClick={() => correction.length > 0 &&
                                                                    speakSentence(correction)
                                                                }
                                                            >
                                                                <img src={Accept} className="w-3 h-3" alt="accept"/>
                                                                <div className="font-bold">
                                                                    {correction}
                                                                    {correction.length > 0 && (
                                                                        <img
                                                                            className="inline-flex ml-1.5 cursor-pointer"
                                                                            src={Speaker}
                                                                            width="16"
                                                                            height="16"
                                                                            alt=""
                                                                        />
                                                                    )}
                                                                </div>
                                                                <SpeakerComponent />
                                                            </div>
                                                        </div>
                                                    ))
                                                )
                                            }
                                            {Object.keys(correctData).length > 0 && (
                                                <p className="font-bold mb-2 mt-4 px-2 bg-[#ededed] rounded-[3px]">{strings.speaking_lesson.daily_lesson.correct_pronunciation}</p>
                                            )}
                                            {correctData.map(({correction}, index) => (
                                                <div key={index} className="mb-2">
                                                    <div className="flex gap-2 items-center">
                                                        <img src={Accept} className="w-3 h-3" alt="accept"/>
                                                        <p>{capitalizeFirstLetter(correction)}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </Accordion>
            </Accordion>

            {/* --- Modal --- */}
            <ModalOverlay ref={modalOverlayRef}/>
            <Modal
                ref={modalRef}
                title={strings.auth.information_messages.modal_title}
                message={modalMessage}
                overlayRef={modalOverlayRef}
                buttonClick={() => closeModal(modalOverlayRef, modalRef)}
            />
        </>
    )
}

export default AttendedInformation