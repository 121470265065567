import React, { useEffect, useState } from "react";
import { AccordionFilter, DropdownMultiselect, Loading } from "../../../components";
import { EventLogs } from "../../../components/Constants";
import useAnalytics from "../../../hooks/useAnalytics";
import { useLocalization } from "../../../hooks/useLocalization";
import { compareTwoArrays, findTagsByIdAndCategory } from "../../../utils";

const DetailSearch = (props) => {

    const { changeDetail, loading, setSendToEndpoint, tagData, tagLoading } = props;
    const strings = useLocalization();
    const analytics = useAnalytics();
    const localData = JSON.parse(localStorage.getItem("videoFilter")) || false;

    const [selectedTags, setSelectedTags] = useState([]);

    const handleFilterButton = () => {
        const selectedTagIds = selectedTags.map(item => item.id);
        const selectedData = {
            tags: selectedTagIds
        };

        if(selectedTagIds.length > 0) {
            const selectedTagForLogs = findTagsByIdAndCategory(selectedTagIds, tagData);
            // LOGGED
            analytics.useAnalytics(EventLogs.VIDEO_EXERCISE_FILTERED,
                { seviye: selectedTagForLogs.level, ilgi_alanlari: selectedTagForLogs.interest }
            );

            selectedData.tagDescriptions = { seviye: selectedTagForLogs.level, ilgi_alanlari: selectedTagForLogs.interest };
        }

        // Seçili bilgilerin saklanması için local storage'a set ediliyor
        localStorage.setItem("videoFilter", JSON.stringify(selectedData));
        setSendToEndpoint(true);
    }

    // Seçili seviye değiştikçe üst componentteki state güncelleniyor
    useEffect(() => {
        const selectedTagIds = selectedTags.map(item => item.id);
        changeDetail?.({ tags: selectedTagIds });
    }, [selectedTags])

    // Local Storage'da tutulan bir veri var ise otomatik olarak dropdown'a dolduruluyor
    useEffect(() => {
        if(localData && tagData.length > 0) {
            const allTagData = tagData.reduce((acc, curr) => acc.concat(curr.tags), []);
            const filteredSelectedTags = allTagData.filter(item => localData?.tags.includes(item.id));

            setSelectedTags(selectedTags => filteredSelectedTags);
        }
    }, [tagData])

    return (
        <AccordionFilter
            title={strings.video_practice.detail_search.title}
            outSideClose={true}
            isDisabled={loading}
            open={localData}
            clickEvent={EventLogs.VIDEO_EXERCISE_FILTER_OPENED}
            content={
                <>
                    {tagLoading && <Loading classnames="mb-4" />}

                    {!tagLoading &&
                        <div className="flex flex-col gap-2 items-center">
                            <div className="flex flex-col lg:flex-row flex-wrap items-center justify-center gap-5 w-full">
                                {tagData.length > 0 &&
                                    tagData.map((item, index) => (
                                        <div key={index} className="flex flex-col items-start">
                                            <label className="text-[14px] font-bold">{item.tagCategoryTitle}</label>
                                            <div className="mt-1">
                                                <DropdownMultiselect
                                                    /* Gelen istek üzerine seviyelerin içinden Starter'i kaldırdıkdetail_search */
                                                    data={item.tags.filter(tag => tag.id !== 2)}
                                                    normalTitle={item.tagCategoryTitle}
                                                    selectedTitle={strings.video_practice.detail_search.selected_data_title
                                                        .replace("#selectedTagTitle#", item.tagCategoryTitle)
                                                    }
                                                    selectedDataCount={compareTwoArrays(item.tags.filter(tag => tag.id !== 2), selectedTags)}
                                                    state={selectedTags}
                                                    className="min-w-[300px]"
                                                    setState={setSelectedTags}
                                                />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <button
                                type="button"
                                className="button !max-w-[330px] primary-button my-7 focus:!bg-primary"
                                onClick={handleFilterButton}
                            >
                                {strings.video_practice.detail_search.list_videos}
                            </button>
                        </div>
                    }
                </>
            }
        />
    )
}

export default DetailSearch;