import ana_sayfa_ko_mobile from "../img/speaking_lesson/none_user/ana_sayfa_ko_mobile.png"
import nonpaid_user from "../img/speaking_lesson/none_user/nonpaid_user.png"
import most_curious_about from "../img/speaking_lesson/none_user/most_curious_about.png"
import play from "../img/speaking_lesson/speaking_lab/play.png"
import check_mark from "../img/speaking_lesson/speaking_lab/check_mark.png"
import wrong from "../img/speaking_lesson/speaking_lab/wrong.png"

import en_flag from "../img/speaking_lesson/daily_sentence/en.png"
import local_flag from "../img/speaking_lesson/daily_sentence/tr.png"

import schedule_calendar from "../img/speaking_lesson/calendar.png"

import app_store from "../img/stores/app_store.png"
import play_store from "../img/stores/play_store.png"
import app_gallery from "../img/stores/app_gallery.png"

import accept from "../img/accept.png"
import multiply from "../img/multiply.png"

import logo_square from "../img/logo-square.png"
import need_permission from "../img/need_permission.png"

import tr_flag from "../img/auth/tr_flag.png"
import usa_flag from "../img/auth/usa_flag.png"

import onboarding_2_2 from "../img/onboarding/second/2.png";
import onboarding_2_4 from "../img/onboarding/second/4.png";
import onboarding_2_5 from "../img/onboarding/second/5.png";
import onboarding_2_6 from "../img/onboarding/second/6.png";
import onboarding_2_7 from "../img/onboarding/second/7.png";
import onboarding_2_9 from "../img/onboarding/second/9.png";

import accept_call from "../img/call/acceptCall.png";
import end_call from "../img/call/endCall.png";

import default_video_thumbnail from "./video_practice/default-video-thumbnail.png";

import american_express from "./bank_logo/american_express.png";
import mastercard from "./bank_logo/mastercard.png";
import troy from "./bank_logo/troy.png";
import visa from "./bank_logo/visa.png";
import axess from "./bank_logo/axess.png";
import neo from "./bank_logo/neo.png";
import paracard from "./bank_logo/paracard.png";
import bonus from "./bank_logo/bonus.png";
import card_finans from "./bank_logo/cardfinans.png";
import qnb_finansbank from "./bank_logo/qnb_finansbank.png";
import halkbank from "./bank_logo/halkbank.png";
import paraf from "./bank_logo/paraf.png";
import is_bankasi from "./bank_logo/is_bankasi.png";
import maximum from "./bank_logo/maximum.png";
import vakifbank from "./bank_logo/vakifbank.png";
import world_card from "./bank_logo/world_card.png";
import tl_card from "./bank_logo/tl_card.png";
import bankkart_kombo from "./bank_logo/bankkart_combo.png";
import ziraat_bank from "./bank_logo/ziraat_logo.png";
import garanti from "./bank_logo/garanti_logo.png";
import shopfly from "./bank_logo/shopfly.png";
import miles_and_smiles from "./bank_logo/miles_and_smiles.png";
import kuveyt_turk from "./bank_logo/kuveyt_turk.png";

import female from "./profile/female.png";
import male from "./profile/male.png";
import none_gender from "./profile/none_gender.png";
import check from "./profile/check.svg";
import default_profile_ko from "./profile/default_profile_ko.png";

export const Ana_Sayfa_Ko_Mobile = ana_sayfa_ko_mobile
export const Nonpaid_User = nonpaid_user
export const Most_Curious_About = most_curious_about
export const Play = play;
export const CheckMark = check_mark;
export const Wrong = wrong;

export const EnFlag = en_flag
export const LocalFlag = local_flag

export const Schedule_Calendar = schedule_calendar

export const App_Store = app_store
export const Play_Store = play_store
export const App_Gallery = app_gallery

export const Accept = accept
export const Multiply = multiply

export const Logo_Square = logo_square
export const Need_Permission = need_permission

export const Tr_Flag = tr_flag
export const Usa_Flag = usa_flag

export const Onboarding_2_2 = onboarding_2_2;
export const Onboarding_2_4 = onboarding_2_4;
export const Onboarding_2_5 = onboarding_2_5;
export const Onboarding_2_6 = onboarding_2_6;
export const Onboarding_2_7 = onboarding_2_7;
export const Onboarding_2_9 = onboarding_2_9;

export const Accept_Call = accept_call;
export const End_Call = end_call;

export const Default_Video_Thumbnail = default_video_thumbnail;

export const AmericanExpress = american_express;
export const Mastercard = mastercard;
export const Troy = troy;
export const Visa = visa;
export const Axess = axess;
export const Neo = neo;
export const Paracard = paracard;
export const Bonus = bonus;
export const CardFinans = card_finans;
export const QnbFinansbank = qnb_finansbank;
export const Halkbank = halkbank;
export const Paraf = paraf;
export const IsBankasi = is_bankasi;
export const Maximum = maximum;
export const Vakifbank = vakifbank;
export const WorldCard = world_card;
export const TlCard = tl_card;
export const BankKartKombo = bankkart_kombo;
export const ZiraatBank = ziraat_bank;
export const Garanti = garanti;
export const Shopfly = shopfly;
export const MilesAndSmiles = miles_and_smiles;
export const KuveytTurk = kuveyt_turk;

export const Female = female;
export const Male = male;
export const NoneGender = none_gender;
export const Check = check;
export const DefaultProfileKo = default_profile_ko;