import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Onboarding_Other } from "../../../assets/svg";
import { Button, HorizontalMenuButton, Loading, ModalOneButton, ModalOverlay } from "../../../components";
import { ViewArchitectureType } from "../../../components/Constants";
import useAuth from "../../../hooks/useAuth";
import { useLocalization } from "../../../hooks/useLocalization";
import { GetOnboardingQuestionById, UpsertMemberOnboardingAnswer } from "../../../services/Onboarding/OnboardingService";
import { closeModal, getLanguageIdByLanguageKey, openModal } from "../../../utils";

const DynamicQuestions = (props) => {

    const { currentQuestionText, currentQuestionId, handleNext } = props;

    const strings = useLocalization();
    const { member } = useAuth();
    const language = useSelector(state => state.localizationStore.language);

    const modalRef = useRef();
    const modalOverlayRef = useRef();

    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [questionData, setQuestionData] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const submitHandle = (e) => {
        e.preventDefault();
        
        setButtonLoading(true);

        UpsertMemberOnboardingAnswer({
            onboardingQuestionId: currentQuestionId,
            memberId: member?.MemberId,
            answers: selectedOptions
        })
            .then(result => {
                setSelectedOptions([]);
                handleNext();
                setButtonLoading(false);
            })
            .catch();
    }

    /*
        * if -> Tıklanılan reason eğer seçili reason'lar arasında yoksa ve seçili değerler maksimum seçme sınırına ulaştıysa modal açılıyor.
        * else if -> Seçilen değer, seçili reason'lar içerisinde var ise kaldırılıyor.
        * else if -> Yeni reason seçili reason'lar içerisine ekleniyor.
    */
    const handleSelectedOption = (optionId) => {
        // Single choice selection
        if (questionData?.maximumChoice === 1) {
            setSelectedOptions([optionId]);
            return;
        }

        // Multiple choice selection (max 5)
        if (!selectedOptions.includes(optionId) && selectedOptions.length === questionData?.maximumChoice) {
            openModal(modalRef, modalOverlayRef);
        } else if (selectedOptions.includes(optionId)) {
            setSelectedOptions(selectedOptions => selectedOptions.filter((id) => optionId !== id));
        } else {
            setSelectedOptions(selectedOptions => [...selectedOptions, optionId]);
        }
    }

    /*
        * View Architecture bilgisine göre görünüm oluşturuluyor.
    */
    const getViewArchitecture = (viewArchitecture) => {
        const props = {
            selectedOptions,
            handleSelectedOption,
            questionData,
            brandName: strings.brand_name
        }

        switch(viewArchitecture) {
           case ViewArchitectureType.VERTICAL:
               return <VerticalView {...props} />;
           case ViewArchitectureType.FRAME:
               return <FrameView {...props} />;
           default:
               return <VerticalView {...props} />;
        }
    }

    /*
        * Onboarding question bilgisi servisten getiriliyor.
    */
    useEffect(() => {
        setLoading(true);

        GetOnboardingQuestionById({
            onboardingQuestionId: currentQuestionId,
            languageTitleType: getLanguageIdByLanguageKey(language),
            memberId: member?.MemberId
        })
            .then(result => {
                if(result.status === 200) {
                    const resultContent = JSON.parse(result.content);
                    
                    setQuestionData(resultContent);
                    setSelectedOptions(resultContent?.memberAnswers);
                }

                setLoading(false);
            })
            .catch();
    }, [language, currentQuestionId]);

    return (
        <>
            {loading && <Loading />}

            {!loading && 
                <>
                    <div className="text-center mx-auto">
                        <p className="text-xl">
                            <span className="font-bold">{currentQuestionText}</span>
                            {" "}{questionData?.question.replace('#BRAND#', strings.brand_name)}
                        </p>
                    </div>

                    <form id="form" onSubmit={submitHandle}>
                        {/* View Architecture bilgisine göre görünüm oluşturuluyor. */}
                        {getViewArchitecture(questionData?.viewArchitecture)}

                        <div className="btn-outer-div mb-3">
                            <Button
                                type="submit"
                                classnames={classNames("button primary-button", { "opacity-70 pointer-events-none": loading })}
                                text={buttonLoading ? strings.general_information_messages.processing : strings.onboarding.next_button}
                                loading={buttonLoading}
                            />
                        </div>
                    </form>
                </>
            }

            <ModalOverlay ref={modalOverlayRef}/>
            <ModalOneButton
                ref={modalRef}
                overlayRef={modalOverlayRef}
                title={strings.auth.information_messages.modal_title}
                message={strings.onboarding.information_messages.max_choice_message.replace('#count#', questionData?.maximumChoice)}
                buttonText={strings.auth.form.okay_button}
                buttonClick={() => closeModal(modalOverlayRef, modalRef)}
            />
        </>
    );
}

const VerticalView = (props) => {

    const { selectedOptions, handleSelectedOption, questionData, brandName } = props;

    return (
        <div className="onboarding-outer-div">
            {questionData?.options.map((item, index) => (
                <HorizontalMenuButton
                    key={index}
                    title={item.option.replace('#BRAND#', brandName)}
                    icon={!item.iconUrl || item.iconUrl.length === 0 ? Onboarding_Other : item.iconUrl}
                    showLink={false}
                    showOnclick={true}
                    isSelected={selectedOptions.includes(item.id)}
                    onclick={() => handleSelectedOption(item.id)}
                    classnames={classNames({
                        "bg-onboarding-bg-select md:text-md text-base": selectedOptions.includes(item.id)
                    })}
                />
            ))}
        </div>
    );
}

const FrameView = (props) => {

    const { selectedOptions, handleSelectedOption, questionData, brandName } = props;

    return (
        <div className="w-[95%] max-xs:justify-between xs-lg:w-[340px] lg:w-[460px] mx-auto mt-[30px] lg:mt-[45px] flex flex-row flex-wrap">
            {questionData?.options.map((item, index) => (
                <button 
                    key={index}
                    type="button" 
                    className={classNames("text-xs h-[100px] w-[30%] xs:w-[100px] flex flex-col justify-center items-center rounded-[10px] border border-onboarding-border mb-4 lg:mb-5 hover:bg-onboarding-bg-select xs:mr-[10px]", {
                        "bg-onboarding-bg-select border-primary": selectedOptions.includes(item.id)
                    })}
                    onClick={() => handleSelectedOption(item.id)}
                >
                    <img 
                        src={!item.iconUrl || item.iconUrl.length === 0 ? Onboarding_Other : item.iconUrl}
                        className="h-[50px] w-[50px] mb-3" 
                        alt="" 
                    />
                    <p>{item.option.replace('#BRAND#', brandName)}</p>
                </button>
            ))}
        </div>
    );
}

export default DynamicQuestions;