import { useEffect, useState } from 'react';
import { AddLessonResultAccordionActivity } from '../services/SpeakingLesson/SpeakingLessonService';

// Akordeon aktivitelerini yönetmek için özel bir hook
const useAccordionActivity = (lessonResultId, initialActivities) => {
  // Aktivitelerin durumunu tutan state
  const [activities, setActivities] = useState([]);

  // Yeni bir akordeon aktivitesi eklemek için fonksiyon
  const addAccordionActivity = (lessonResultAccordionType) => {
    // Akordeonun açık olup olmadığını kontrol et
    const isAccordionOpened = activities.find(
      (l) => lessonResultAccordionType === l.lessonResultAccordionType
    )?.isOpened;

    // Eğer akordeon açık değilse, yeni aktivite ekle
    if (!isAccordionOpened) {
      AddLessonResultAccordionActivity(lessonResultId, lessonResultAccordionType)
        .then((result) => {
          if (result.status === 200) {
            const resultContent = JSON.parse(result.content);
            // Aktiviteler listesini güncelle
            setActivities((prevActivities) =>
              prevActivities.map((item) =>
                item.lessonResultAccordionType === lessonResultAccordionType
                  ? { ...item, isOpened: resultContent }
                  : item
              )
            );
          }
        })
        .catch();
    }
  };

  // initialActivities değiştiğinde activities state'ini güncelle
  useEffect(() => {
    setActivities(initialActivities);
  }, []);

  // Hook'un dönüş değerleri
  return { activities, addAccordionActivity };
};

export default useAccordionActivity;
