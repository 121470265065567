import { useEffect, useState } from "react";

const useCurrentTimeStamp = () => {

    const [remoteTimeStamp, setRemoteTimeStamp] = useState(0);

    // Mesai saatlerini kontrol eden fonksiyon
    const checkIsWithinWorkingHours = (unixTimestamp) => {
        // Unix timestamp'i Date nesnesine çevir (UTC)
        const utcDate = new Date(unixTimestamp);

        // UTC saat ve dakikayı al
        const utcHour = utcDate.getUTCHours();
        const utcMinute = utcDate.getUTCMinutes();

        // Türkiye mesai saatleri (UTC+3)
        const workStartHourUTC = 6; // 09:00 TR -> 06:00 UTC
        const workEndHourUTC = 15;  // 18:00 TR -> 15:00 UTC
        const workEndMinuteUTC = 30;

        // Mesai saatleri içinde mi kontrol et
        if (utcHour > workStartHourUTC && utcHour < workEndHourUTC) {
            return true;
        } else if (utcHour === workStartHourUTC && utcMinute >= 0) {
            return true;
        } else if (utcHour === workEndHourUTC && utcMinute < workEndMinuteUTC) {
            return true;
        }
        
        return false;
    };

    const isWithinWorkingHours = checkIsWithinWorkingHours(remoteTimeStamp);

    useEffect(() => {
        fetch("https://e-teacher.org/timestamp/", { 
            method: "GET" 
        })
            .then(response => response.json())
            .then(json => {
                setRemoteTimeStamp(json);
            })
            .catch()
    }, []);

    return { remoteTimeStamp,  isWithinWorkingHours };
}

export default useCurrentTimeStamp;