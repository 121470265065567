import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SpeakingLabPronunciation } from "../../../../../../assets/svg";
import { Accordion, HorizontalMenuButton } from "../../../../../../components";
import { EventLogs, TermLessonAccordionType } from "../../../../../../components/Constants";
import { useLocalization } from "../../../../../../hooks/useLocalization";
import useTermAccordionActivity from "../../../../../../hooks/useTermAccordionActivity";
import { url } from "../../../../../../routes/utility";
import { formatDateToCompactInteger } from "../../../../../../utils";
import useAnalytics from "../../../../../../hooks/useAnalytics";

const SpeakingLabContent = ({ bookUnitId, selectedDate, termLessonAccordionActivities }) => {

    const strings = useLocalization();
    const { activities, addTermAccordionActivity } = useTermAccordionActivity(
        termLessonAccordionActivities
    );
    const navigate = useNavigate();
    const analytics = useAnalytics();

    const selectedTermId = useSelector(state => state.termStore.termId) || false;

    const handleRouteSpeakingLab = () => {
        navigate(url("speakinglesson.speakinglab"), { state: { 
            bookUnitId: bookUnitId, 
            selectedDate: formatDateToCompactInteger(selectedDate), 
            selectedDateWithoutFormat: selectedDate 
        }});
        analytics.useAnalytics(EventLogs.GUNUN_DERSI_SPEAKING_LAB_CLICK);
    }

    return (
        <Accordion
            title={strings.speaking_lesson.speaking_lab.title}
            disableOutsideClick={true}
            isScrollable={false}
            isDownArrowIcon={false}
            openCloseCheckboxStatus={
                activities
                    ?.find(l => TermLessonAccordionType.SPEAKING_LAB === l.termLessonAccordionType)?.isOpened
                || false
            }
            action={() => addTermAccordionActivity(TermLessonAccordionType.SPEAKING_LAB, selectedTermId, selectedDate)}
        >
            <div className="py-6 flex flex-col items-center justify-center">
                <p className="text-center">{strings.speaking_lesson.speaking_lab.description_content}</p>
                <div className="min-w-[330px] mx-auto pt-6">
                    <HorizontalMenuButton
                        title={strings.speaking_lesson.speaking_lab.go_lab}
                        icon={SpeakingLabPronunciation}
                        onclick={handleRouteSpeakingLab}
                        classnames="bg-white"
                    />
                </div>
            </div>
        </Accordion>
    )
}

export default SpeakingLabContent;