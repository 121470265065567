import {Accordion, HorizontalMenuButton} from "../../../../../../components";
import React from "react";
import {ImproveBySpeaking, QuizTest} from "../../../../../../assets/svg";
import {useLocalization} from "../../../../../../hooks/useLocalization";
import {useNavigate} from "react-router-dom";
import {url} from "../../../../../../routes/utility";
import useAiRouting from "../../../../../../hooks/useAiRouting";
import {AIKeys, LessonResultAccordionType} from "../../../../../../components/Constants";
import useAccordionActivity from "../../../../../../hooks/useAccordionActivity";

const LessonStudy = (props) => {

    const { isBookHaveQuiz, bookUnitId, selectedDate, lessonResultId, lessonResultAccordionActivities } = props;

    const strings = useLocalization();
    const navigate = useNavigate();
    const { activities, addAccordionActivity } = useAccordionActivity(lessonResultId, lessonResultAccordionActivities);

    const correctionAiId = useAiRouting(AIKeys.CORRECTION);

    const handleRouteQuiz = () => {
        navigate(
            url("speakinglesson.selectedquiz",
                { bookUnitId: bookUnitId }),
            { state: { date: selectedDate }}
        );
    }

    const handleRouteAi = () => {
        navigate(
            url("speakinglesson.chatbot",
                { useCaseId: correctionAiId }),
            { state: { date: selectedDate }}
        )
    };

    return (
        <Accordion
            title={strings.speaking_lesson.daily_lesson.lesson_study}
            disableOutsideClick={true}
            isScrollable={false}
            isDownArrowIcon={false}
            openCloseCheckboxStatus={
                activities
                    ?.find(l => LessonResultAccordionType.AFTER_LESSON_ACTIVITIES === l.lessonResultAccordionType)?.isOpened
                || false
            }
            action={() => addAccordionActivity(LessonResultAccordionType.AFTER_LESSON_ACTIVITIES)}
        >
            <div className="max-w-[330px] mx-auto pt-3">
                <HorizontalMenuButton
                    title={strings.speaking_lesson.daily_lesson.correct_and_speak}
                    icon={ImproveBySpeaking}
                    onclick={handleRouteAi}
                />
                {isBookHaveQuiz &&
                    <HorizontalMenuButton
                        title={strings.speaking_lesson.speaking_lesson_user.quiz_test}
                        icon={QuizTest}
                        onclick={handleRouteQuiz}
                    />
                }
            </div>
        </Accordion>
    )
}

export default LessonStudy;