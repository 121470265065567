import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { Onboarding_2_2, Onboarding_2_4, Onboarding_2_5, Onboarding_2_6, Onboarding_2_7, Onboarding_2_9 } from "../../../assets/img";
import { Onboarding_2_1, Onboarding_2_3, Onboarding_2_8 } from "../../../assets/svg";
import { Button, HorizontalMenuButton, Loading, ModalOneButton, ModalOverlay } from "../../../components";
import useAnalytics from "../../../hooks/useAnalytics";
import useAuth from "../../../hooks/useAuth";
import { useLocalization } from "../../../hooks/useLocalization";
import useMemberSurvey from "../../../hooks/useMemberSurvey";
import { UpdateMemberReasonToLearnEnglish } from "../../../services/Auth/AuthService";
import { closeModal, openModal } from "../../../utils";

const ReasonToLearnEnglishQuestion = (props) => {

    const { currentQuestionText, handleNext } = props;

    const strings = useLocalization();
    const analytics = useAnalytics();

    const {survey, surveyLoading} = useMemberSurvey();
    const { member } = useAuth();

    const modalRef = useRef();
    const modalOverlayRef = useRef();

    const MAX_SELECTED_OPTIONS = 5;

    const [modalMessage, setModalMessage] = useState("");
    const [selectedReasons, setSelectedReasons] = useState([]);
    const [loading, setLoading] = useState(false);

    const reasons = [
        { id: 1, title: strings.onboarding.second.use_case1, icon: Onboarding_2_9},
        { id: 2, title: strings.onboarding.second.use_case2, icon: Onboarding_2_8},
        { id: 3, title: strings.onboarding.second.use_case3, icon: Onboarding_2_7},
        { id: 4, title: strings.onboarding.second.use_case4, icon: Onboarding_2_6},
        { id: 5, title: strings.onboarding.second.use_case5, icon: Onboarding_2_5},
        { id: 6, title: strings.onboarding.second.use_case6, icon: Onboarding_2_4},
        { id: 7, title: strings.onboarding.second.use_case7, icon: Onboarding_2_3},
        { id: 8, title: strings.onboarding.second.use_case8, icon: Onboarding_2_2},
        { id: 9, title: strings.onboarding.second.use_case9, icon: Onboarding_2_1}
    ];

    /*
        * if -> Tıklanılan reason eğer seçili reason'lar arasında yoksa ve seçili değerler maksimum seçme sınırına ulaştıysa modal açılıyor.
        * else if -> Seçilen değer, seçili reason'lar içerisinde var ise kaldırılıyor.
        * else if -> Yeni reason seçili reason'lar içerisine ekleniyor.
    */
    const handleSelectedReason = (reasonId) => {
        if(!selectedReasons.includes(reasonId) && selectedReasons.length === MAX_SELECTED_OPTIONS) {
            setModalMessage(modalMessage => strings.onboarding.information_messages.max_choice_message.replace('#count#', MAX_SELECTED_OPTIONS));
            openModal(modalOverlayRef, modalRef);
        } else if (selectedReasons.includes(reasonId)) {
            setSelectedReasons(selectedReasons => selectedReasons.filter((id) => reasonId !== id));
        } else {
            setSelectedReasons(selectedReasons => [...selectedReasons, reasonId]);
        }
    }

    const submitHandle = async e => {
        e.preventDefault();

        if (selectedReasons.length > 0) {

            setLoading(true);

            let model = {
                memberId: member?.MemberId,
                memberReasonTypeIds: selectedReasons
            }

            const result = await UpdateMemberReasonToLearnEnglish(model);

            if (result.status === 200) {
                analytics.useAnalytics('Onboarding_2');
            } else {
                let resultContent = JSON.parse(result.content);

                if (resultContent) {
                    analytics.useAnalytics('Error_Occured', {
                        error: `${resultContent}`,
                    });
                }
                else {
                    analytics.useAnalytics('Error_Occured', {
                        error: `An unexpected error occurred.`,
                    });
                }
            }

            setLoading(false);
            handleNext();
        } else {
            setModalMessage(modalMessage => strings.onboarding.information_messages.make_a_choice);
            openModal(modalOverlayRef, modalRef);
        }
    }

    useEffect(() => {
        if(survey?.first?.length > 0)
            setSelectedReasons(selectedReasons => survey.first);
    }, [survey]);

    return (
        <>
            {surveyLoading && 
                <div className='w-full mx-auto mt-5'>
                    <Loading/>
                </div>
            }

            {!surveyLoading && (
                <>
                    <div className="text-center mx-auto">
                        <p className="text-xl">
                            <span className="font-bold">{currentQuestionText}</span>
                            {" "}{strings.onboarding.second.title}
                        </p>
                    </div>
                    <form id="form" onSubmit={submitHandle}>
                        <div className="onboarding-outer-div">
                            {reasons.map((reason, index) => (
                                <HorizontalMenuButton
                                    key={index}
                                    title={reason.title}
                                    icon={reason.icon}
                                    showLink={false}
                                    showOnclick={true}
                                    isSelected={selectedReasons.includes(reason.id)}
                                    onclick={() => handleSelectedReason(reason.id)}
                                    classnames={classNames({
                                        "bg-onboarding-bg-select md:text-md text-base": selectedReasons.includes(reason.id)
                                    })}
                                />
                            ))}
                        </div>
                        <div className="btn-outer-div mb-3">
                            <Button
                                type="submit"
                                classnames={classNames("button primary-button", { "opacity-70 pointer-events-none": loading })}
                                text={loading ? strings.general_information_messages.processing : strings.onboarding.next_button}
                                loading={loading}
                            />
                        </div>
                    </form>
                </>
            )}

            {/* --- Modal --- */}
            <ModalOverlay ref={modalOverlayRef}/>
            <ModalOneButton
                ref={modalRef}
                overlayRef={modalOverlayRef}
                title={strings.auth.information_messages.modal_title}
                message={modalMessage}
                buttonText={strings.auth.form.okay_button}
                buttonClick={() => closeModal(modalOverlayRef, modalRef)}
            />
        </>
    )
}

export default ReasonToLearnEnglishQuestion;