import { useCallback, useRef, useState } from "react";
import { ModalOneButton, ModalOverlay } from "../components";
import { TtsWithSave } from "../services/SpeakingLab/SpeakingLabService";
import { useLocalization } from "./useLocalization";
import { closeModal, openModal } from "../utils";

const useTts = () => {

    const strings = useLocalization();

    const modalRef = useRef();
    const audioRef = useRef();
    const modalOverlayRef = useRef();

    const [isSpeakingComplete, setIsSpeakingComplete] = useState(false);

    const speakSentence = useCallback(async (sentence, lang, textId) => {
        setIsSpeakingComplete(false);

        if(textId === 'correct-answer' || textId === 'wrong-answer') {
            return new Promise((resolve) => {
                const audio = new Audio(textId === 'correct-answer' 
                    ? '/sound/correct_answer.mp3' 
                    : '/sound/wrong_answer.mp3');
    
                audio.onended = () => {
                    setIsSpeakingComplete(true);
                    resolve(true);
                };
    
                audio.onerror = (error) => {
                    console.error('Audio playback error:', error);
                    setIsSpeakingComplete(true);
                    resolve(false);
                };
    
                audio.play().catch(error => {
                    console.error('Play error:', error);
                    setIsSpeakingComplete(true);
                    resolve(false);
                });
            });
        }

        try {
            const result = await TtsWithSave(sentence, lang, textId);

            if (result.statusCode === 200) {
                return new Promise((resolve) => {
                    audioRef.current.src = result.message.audio;

                    audioRef.current.onended = () => {
                        setIsSpeakingComplete(true);
                        resolve(true);
                    };

                    audioRef.current.play().catch(error => {
                        resolve(false);
                    });
                });
            } else {
                openModal(modalOverlayRef, modalRef);
                return false;
            }
        } catch (err) {
            openModal(modalOverlayRef, modalRef);
            return false;
        }
    }, []);

    const SpeakerComponent = () => {
        return (
            <>
                {/* Sesin çalması için gerekli olan kısım */}
                <audio ref={audioRef} id="audioPlayer" controls={false} autoPlay={false} className="hidden">
                    <source type="audio/mp3" />
                </audio>
                <ModalOverlay ref={modalOverlayRef} />
                <ModalOneButton
                    ref={modalRef}
                    overlayRef={modalOverlayRef}
                    title={strings.auth.information_messages.modal_title}
                    message={strings.menu.callbar.an_error_occured}
                    buttonText={strings.auth.form.okay_button}
                    buttonClick={() => closeModal(modalOverlayRef, modalRef)}
                />
            </>
        )
    }

    return {
        speakSentence,
        SpeakerComponent,
        isSpeakingComplete
    }
}

export default useTts;