import React, {useEffect, useRef, useState} from "react";
import { Speaker } from "../../../../../../assets/svg";
import useAnalytics from "../../../../../../hooks/useAnalytics";
import {SpeakWordWithTts} from "../../../../../../services/SpeakingLesson/SpeakingLessonService";
import {ModalOneButton, ModalOverlay} from "../../../../../../components";
import {closeModal, openModal} from "../../../../../../utils";
import {useLocalization} from "../../../../../../hooks/useLocalization";

const Words = ({ title, data, events, analyticsPass = false }) => {

  const strings = useLocalization();
  const analytics = useAnalytics();

  const audioRef = useRef();
  const errorModalRef = useRef();
  const modalOverlayRef = useRef();

  const [currentWord, setCurrentWord] = useState({ word: '', translation: '' });

  //speak word
  const speakWord = async (word) => {
    const result = await SpeakWordWithTts(word);

    if(result.statusCode === 200) {
      audioRef.current.src = result.message.audio;

      // Ses çalmayı başlat
      audioRef.current.play();
    } else {
      openModal(modalOverlayRef, errorModalRef);
    }
  };

  //analytics
  useEffect(() => {
    if (currentWord.word !== '' && !analyticsPass) {
      analytics.useAnalytics(events[0]);
      analytics.useAnalytics(events[1]);
    }

  }, [currentWord])

  return (
    <>
      <div className="transparent-section mt-5">
        <h5 className="text-secondary">{title}</h5>
        <div id="word_buttons" className="mt-4">
          {data.map((item, key) => (
            <button
                key={key}
                type="button"
                className={`word-button group ${currentWord.word === item.word ? "border-nav-button-bg text-base-text bg-box" : ""}`}
                onClick={() => {
                  speakWord(item.word)
                  setCurrentWord({ word: item.word, translation: item.translation });
                }}
            >
              {currentWord.word === item.word ? item.word + " : " + item.translation : item.word} {" "}
              <img
                className={`inline-flex ml-[2px] ${currentWord.word !== item.word ? "hidden" : ""}`}
                src={Speaker}
                width="16"
                height="16"
                alt=""
              />
              <br />
              <audio ref={audioRef} id="audioPlayer" controls={false} autoPlay={false} className="hidden">
                <source type="audio/mp3"/>
              </audio>
            </button>
          ))}
        </div>
      </div>

      <ModalOverlay ref={modalOverlayRef} />
      <ModalOneButton
          ref={errorModalRef}
          overlayRef={modalOverlayRef}
          title={strings.auth.information_messages.modal_title}
          message={strings.menu.callbar.an_error_occured}
          buttonText={strings.auth.form.okay_button}
          buttonClick={() => closeModal(modalOverlayRef, errorModalRef)}
      />
    </>
  );
};

export default Words;
