import { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const usePageLeave = (delay = 0, shouldConfirm = false) => {
    
    const [isLeaving, setIsLeaving] = useState(false);
    const location = useLocation();
    const callbackRef = useRef(null);
    const timeoutRef = useRef(null);

    const setCallback = useCallback((callback) => {
        callbackRef.current = callback;
    }, []);

    const handlePageLeave = useCallback(() => {
        if (!isLeaving) {
            setIsLeaving(true);
            if (callbackRef.current) {
                // Doğrudan dışarıdan gelen fonksiyonu çalıştır
                callbackRef.current();
            }

            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            timeoutRef.current = setTimeout(() => {
                setIsLeaving(false);
            }, delay);
        }
    }, [isLeaving, delay]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (shouldConfirm) {
                event.preventDefault();
                event.returnValue = '';
                const confirmationMessage = 'Are you sure you want to leave this page?';
                (event || window.event).returnValue = confirmationMessage;
                handlePageLeave();
                return confirmationMessage;
            }
        };

        const handlePopState = (event) => {
            if (shouldConfirm) {
                const confirmLeave = window.confirm('Are you sure you want to leave this page?');
                if (confirmLeave) {
                    handlePageLeave();
                } else {
                    event.preventDefault();
                    window.history.pushState(null, '', window.location.href);
                }
            }
        };

        if (shouldConfirm) {
            window.addEventListener('beforeunload', handleBeforeUnload);
            window.addEventListener('popstate', handlePopState);
            window.history.pushState(null, '', window.location.href);
        }

        return () => {
            handlePageLeave();
            if (shouldConfirm) {
                window.removeEventListener('beforeunload', handleBeforeUnload);
                window.removeEventListener('popstate', handlePopState);
            }
        };
    }, [location, handlePageLeave, shouldConfirm]);

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    return { isLeaving, setCallback };
};

export default usePageLeave;