import { useRef, useState } from "react";
import { ModalOneButton, ModalOverlay, ModalTwoButtons } from "../components";
import { ScoreType } from "../components/Constants";
import { AddMemberPronunciationResult, GetSpeechReport } from "../services/VideoPractice/VideoPracticeService";
import { closeModal, getBrowserName, getDeviceOSName, getPermissionTutorialVideoUrl, openModal } from "../utils";
import { useLocalization } from "./useLocalization";

const useSpeechStudio = (pronunciationInfo) => {

    const strings = useLocalization();

    const modalOverlayRef = useRef(null);
    const modalRef = useRef(null);
    const errorModalRef = useRef(null);
    const recorderRef = useRef();

    const browser = getBrowserName();
    const operatingSystem = getDeviceOSName();

    const [isRecording, setIsRecording] = useState(false);
    const [isFirstRecordingCompleted, setIsFirstRecordingCompleted] = useState(false);
    const [showAnimation, setShowAnimation] = useState(false)
    const [speechStudioResult, setSpeechStudioResult] = useState(null);
    const [speechStudioLoading, setSpeechStudioLoading] = useState(false);
    const [isErrorOccured, setIsErrorOccured] = useState(false);
    const [audioKey, setAudioKey] = useState(0);

    const startRecording = async (content, memberId = null) => {
        let chunks = [];

        try {

            setIsErrorOccured(false);

            const stream = await navigator.mediaDevices.getUserMedia({audio: true}); // To get access to the microphone
            recorderRef.current = new MediaRecorder(stream); // To create a media recorder object

            recorderRef.current.addEventListener('dataavailable', (event) => {
                chunks.push(event.data); // To store the audio data in chunks
            });

            recorderRef.current.addEventListener('stop', async () => {
                const blob = new Blob(chunks, {type: 'audio/wav'}); // To create a blob object from the chunks
                const formData = new FormData(); // To create a form data object
                formData.append('audio', blob); // To append the blob object to the form data
                formData.append('text', content);
                memberId && formData.append('memberId', memberId);
                setSpeechStudioLoading(true);
                const result = await GetSpeechReport(formData)

                try {
                    const resultContent = JSON.parse(result);
                    setSpeechStudioResult(resultContent);
                    sendSpeechStudioResult(resultContent);
                    setIsFirstRecordingCompleted(true);
                    setAudioKey(prevKey => prevKey + 1);
                } catch (error) {
                    setIsErrorOccured(true);
                    openModal(modalOverlayRef, errorModalRef);
                }

                setSpeechStudioLoading(false);
                // Reset the chunks array
                chunks = [];
            });

            setIsRecording(true);
            recorderRef.current.start(); // To start recording audio
            setTimeout(() => { setShowAnimation(true)}, 800);

        } catch (error) {
            setIsErrorOccured(true);
            openModal(modalOverlayRef, modalRef);
            console.error('Error accessing microphone:', error); // To log the error message
        }
    }

    const stopRecording = () => {
        setIsRecording(false);
        setShowAnimation(false);
        recorderRef.current.stop();
    }

    const sendSpeechStudioResult = (result) => {
        const model = {
            pronunciationId: pronunciationInfo?.contentId,
            memberId: pronunciationInfo?.memberId,
            pronunciationResultType: pronunciationInfo?.source,
            pronunciationScore: result?.pron_score,
            memberPronunciationResultScores: [
                {
                    scoreType: ScoreType.PRONUNCIATION,
                    score: result?.pron_score
                },
                {
                    scoreType: ScoreType.ACCURACY,
                    score: result?.accuracy_score
                },
                {
                    scoreType: ScoreType.COMPLETENESS,
                    score: result?.completeness_score
                },
                {
                    scoreType: ScoreType.FLUENCY,
                    score: result?.fluency_score
                },
                {
                    scoreType: ScoreType.PROSODY,
                    score: result?.prosody_score
                }
            ]
        }

        AddMemberPronunciationResult(model)
            .then()
            .catch()
    }

    const handleSpeechStudioRecording = (content, memberId) => {
        if (isRecording) {
            stopRecording();
        } else {
            startRecording(content, memberId)
                .then()
                .catch()
        }
    }

    const handleResetStates = () => {
        setSpeechStudioResult(null);
        setSpeechStudioLoading(false);
        setShowAnimation(false);
        setIsRecording(false);
    }

    const handleResetIsFirstRecordingCompleted = () => {
        setIsFirstRecordingCompleted(false);
        setIsErrorOccured(false);
    }

    const SpeechStudioModals = () => (
        <>
            <ModalOverlay ref={modalOverlayRef}/>
            <ModalTwoButtons
                ref={modalRef}
                overlayRef={modalOverlayRef}
                title={strings.auth.information_messages.modal_title}
                message={strings.video_practice.word_card.give_microphone_permission}
                buttonText1={strings.auth.form.okay_button}
                buttonClick1={() => closeModal(modalOverlayRef, modalRef)}
                buttonText2={strings.auth.form.watch_video}
                buttonClick2={() => window.open(getPermissionTutorialVideoUrl(operatingSystem, browser))}
            />
            <ModalOneButton
                ref={errorModalRef}
                overlayRef={modalOverlayRef}
                title={strings.auth.information_messages.modal_title}
                message={strings.general_information_messages.an_error_occured}
                buttonText={strings.auth.form.okay_button}
                buttonClick={() => closeModal(modalOverlayRef, errorModalRef)}
            />
        </>
    );

    return {
        handleSpeechStudioRecording,
        handleResetStates,
        handleResetIsFirstRecordingCompleted,
        showAnimation,
        isRecording,
        isFirstRecordingCompleted,
        SpeechStudioModals,
        speechStudioLoading,
        speechStudioResult,
        isErrorOccured,
        audioKey
    };
}

export default useSpeechStudio;