import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import Lottie from "lottie-react";
import { Loading, SentenceBookmarkButton } from '../../../../components';
import { EventLogs, PronunciationErrors, PronunciationResultType } from '../../../../components/Constants';
import useAuth from '../../../../hooks/useAuth';
import { useLocalization } from "../../../../hooks/useLocalization";
import useSpeechStudio from '../../../../hooks/useSpeechStudio';
import useWindowSize from '../../../../hooks/useWindowSize';
import { removePunctuation } from '../../../../utils';
import { ScoreCard } from '../../../Exercises/components';
import { SpeakingLabCorrect, SpeakingLabDragDropSentence, SpeakingLabPlaybackOptions, SpeakingLabWrong } from './components';
import useAnalytics from '../../../../hooks/useAnalytics';

const SpeakingLabCreateSentence = ({ exerciseData, onNext, speakSentence, isSpeakingComplete, dynamicPassingScore, ttsInfo, defaultOptions }) => {
    
    const strings = useLocalization();
    const { member } = useAuth();
    const {
        handleSpeechStudioRecording,
        handleResetStates,
        handleResetIsFirstRecordingCompleted,
        showAnimation,
        isFirstRecordingCompleted,
        speechStudioLoading,
        speechStudioResult,
        isErrorOccured,
        audioKey
    } = useSpeechStudio({
        contentId: exerciseData?.exercise?.sentenceId,
        memberId: member?.MemberId,
        source: PronunciationResultType.SPEAKING_LAB
    });
    const windowSize = useWindowSize();
    const analytics = useAnalytics();

    const isMobile = windowSize?.width < 640;

    // Drag&Drop ile oluşturulan cümle burada tutuluyor
    const [currentSentence, setCurrentSentence] = useState("");
    // Drag&Drop ile oluşturulan cümle doğru mu kontrol ediliyor
    const [isDraggedSentenceCorrect, setIsDraggedSentenceCorrect] = useState(null);
    // Pronunciation ile oluşturulan cümle doğru mu kontrol ediliyor
    const [isPronunciationCorrect, setIsPronunciationCorrect] = useState(null);
    // Telaffuz etme alanına geçilip geçilmediğini tutacak state
    const [showPronunciationExercise, setShowPronunciationExercise] = useState(false);
    // Cümle doğru olduktan sonra 
    const [showAvailableList, setShowAvailableList] = useState(true);
    // Cümle sırasının değiştirilebilir olup olmadığını tutacak state
    const [isDraggable, setIsDraggable] = useState(true);
    // Cümle sıfırlama durumlarını kontrol etmek için kullanılan state
    const [resetTrigger, setResetTrigger] = useState(0);
    // Yanlış cevap sayısını tutan yeni state
    const [wrongAttempts, setWrongAttempts] = useState(0);

    // Drag&Drop ile cümle değiştirildiğinde, alt componentten buraya cümle gönderiliyor
    const handleSentenceChange = useCallback((newSentence) => {
        setCurrentSentence(newSentence);
    }, []);

    // Kontrole et butonuna tıklandığında Drag&Drop ile cümle doğru mu kontrol ediliyor
    const checkSentence = useCallback(() => {
        const normalizeString = (str) => removePunctuation(str.toLowerCase().trim());
        
        const userSentence = normalizeString(currentSentence);
        const correctSentence = normalizeString(exerciseData?.exercise?.sentence);
        
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth'
        });

        setIsDraggedSentenceCorrect(userSentence === correctSentence);
    }, [currentSentence, exerciseData?.exercise?.sentence]);

    // Drag and Drop componentine cümleyi sıfırlamak için kullanılıyor
    const handleReset = () => {
        setResetTrigger(prev => prev + 1);
        setCurrentSentence("");
        setIsDraggedSentenceCorrect(null);
        setIsDraggable(true);
        window.scrollTo(0, 0);
    };

    // Cümle sırasını doğru yapıp, telaffuz kısımına geçerken kullanılan fonksiyon
    const handleStartPronunciationExercise = (sentence) => {
        setShowAvailableList(false);
        setShowPronunciationExercise(true);
        window.scrollTo(0, 0);
        handleSpeechStudioRecording(removePunctuation(sentence), member?.MemberId);
    }

    // Yanlış cevap componentine gönderildiğinde cümleyi tekrar seslendirmek için kullanılır.
    const handlePronunciation = async (sentence) => {
        window.scrollTo(0, 0);
        handleSpeechStudioRecording(removePunctuation(sentence), member?.MemberId);
        handleResetStates();
        setIsDraggedSentenceCorrect(null);
        setIsPronunciationCorrect(null);
        analytics.useAnalytics(EventLogs.SPEAKING_LAB_PRONOUNCE_CLICKED);
    }

    // Cümleyi tekrar seslendirmek için kullanılır.
    const handleTryAgain = async () => {
        handleResetIsFirstRecordingCompleted();
        handleResetStates();
        setIsDraggedSentenceCorrect(null);
        setIsPronunciationCorrect(null);
        window.scrollTo(0, 0);
        await speakSentence(ttsInfo.sentence, ttsInfo.language, ttsInfo.textId);

        analytics.useAnalytics(EventLogs.SPEAKING_LAB_TRY_AGAIN_CLICKED,{
            source: "drag_and_drop"
        });
    }

    const handleContinue = () => {
        onNext();
        analytics.useAnalytics(EventLogs.SPEAKING_LAB_CONTINUE_CLICKED, {
            source: "drag_and_drop"
        });
    }

    // Doğru / Yanlış cevapların kontrolü ekleniyor.
    const renderResult = () => {

        if(isErrorOccured)
            return <SpeakingLabWrong textInfoTitle={strings.speaking_lesson.speaking_lab.pronunce_error_text} buttonClick={handleTryAgain} />;

        if(!showPronunciationExercise && isDraggedSentenceCorrect !== null) {
            if (!isDraggedSentenceCorrect) 
                return <SpeakingLabWrong buttonClick={handleReset} textInfoTitle={strings.speaking_lesson.speaking_lab.drag_drop_wrong_message} />;
            else
                return <SpeakingLabCorrect buttonClick={() => handleStartPronunciationExercise(exerciseData?.exercise?.sentence)} textInfoTitle={strings.speaking_lesson.speaking_lab.drag_drop_correct_message} buttonText={strings.video_practice.word_card.word_pronunciation} />;
        }

        if(showPronunciationExercise && isPronunciationCorrect !== null) {
            if(!isPronunciationCorrect)
                return <SpeakingLabWrong textInfo={speechStudioResult?.words} buttonClick={handleTryAgain} skipButtonCondition={wrongAttempts >= 3} skipButtonClick={onNext} />;
            else
                return <SpeakingLabCorrect textInfo={speechStudioResult?.words} buttonClick={handleContinue} />;
        }
    };

    // Kullanıcı cümle sırasını tamamladıysa isDraggable state'ini false yapıyor.
    // Bu sayede kullanıcı cümle sırasını değiştiremez.
    useEffect(() => {
        if(isDraggedSentenceCorrect !== null)
            setIsDraggable(false);
    }, [isDraggedSentenceCorrect]);

    // Sayfaya ilk render edildiğinde, cümleyi seslendirir.
    useEffect(() => {
        if(Object.keys(ttsInfo).length > 0)
            speakSentence(ttsInfo.sentence, ttsInfo.language, ttsInfo.textId);
    }, []);

    /*
        Bu kod, `speechStudioResult` ve `speechStudioLoading` değişkenlerini izler.
        Eğer `speechStudioResult` varsa ve `speechStudioLoading` false ise
        `isCorrect` değişkenini `speechStudioResult.Score` değişkeni ile `dynamicPassingScore` değişkenini karşılaştırarak günceller.
    */
    useEffect(() => {   
        const playFeedbackSound = async (isCorrect) => {
            isCorrect
                ? await speakSentence('', '', 'correct-answer')
                : await speakSentence('', '', 'wrong-answer');
        };

        if(speechStudioResult && !speechStudioLoading) {
            const passedScore = speechStudioResult.pron_score >= dynamicPassingScore;
            const hasErrors = speechStudioResult.words.some(word => {
                return word.error_type === PronunciationErrors.MISPRONUNCIATION || 
                        word.error_type === PronunciationErrors.OMISSION;
            });
    
            const isCorrect = !hasErrors && passedScore;
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth'
            });
            playFeedbackSound(isCorrect);        
            setIsPronunciationCorrect(isCorrect);

            // Eğer cevap yanlışsa, wrongAttempts'i artır
            if (!isCorrect) {
                setWrongAttempts(prevAttempts => prevAttempts + 1);
            }
        }
    }, [speechStudioResult, speechStudioLoading, dynamicPassingScore]);

    useEffect(() => {
        analytics.useAnalytics(EventLogs.SPEAKING_LAB_CREATE_SENTENCE_IMPRESSION);
    }, [])

    return (
        <>
            <p className="my-5 text-center">
                {(isDraggedSentenceCorrect === null && !showPronunciationExercise) && 
                    strings.speaking_lesson.speaking_lab.create_sentence_title
                }

                {(isDraggedSentenceCorrect !== null && !isDraggedSentenceCorrect) &&
                    strings.speaking_lesson.speaking_lab.create_sentence_wrong
                }

                {(showPronunciationExercise || isDraggedSentenceCorrect) && 
                   strings.speaking_lesson.speaking_lab.create_sentence_correct
                }
            </p>
            <div className="gray-section min-h-[300px] flex flex-col">
                <div className="w-full flex justify-end mb-5">
                    <SentenceBookmarkButton 
                        initialIsSaved={exerciseData?.exercise?.isSavedSentence} 
                        sentenceId={exerciseData?.exercise?.sentenceId} 
                        logEvent={{
                            true: EventLogs.SPEAKING_LAB_ADDED_TO_LEARNING_LIST,
                            false: EventLogs.SPEAKING_LAB_REMOVED_TO_LEARNING_LIST
                        }}
                    />
                </div>
                <div className='flex flex-col gap-2 justify-start mt-8'>
                    <SpeakingLabDragDropSentence 
                        wordsList={exerciseData?.exercise?.words} 
                        onSentenceChange={handleSentenceChange}
                        resetTrigger={resetTrigger}
                        showAvailableList={showAvailableList}
                        isDraggable={isDraggable}
                    />
                </div>

                <div className="flex-grow"></div>

                <div className="flex flex-col gap-3">
                    {showPronunciationExercise && 
                        <>
                            {exerciseData?.exercise?.hint ?
                                <p className="text-[14px]">
                                    <span className="font-bold">{strings.speaking_lesson.speaking_lab.sentence_hint}:{" "}</span>
                                    {exerciseData?.exercise?.hint}
                                </p>
                                : <p className="text-[14px]">
                                    {exerciseData?.exercise?.sentenceTranslation}
                                  </p>
                            }
                        </>
                    }
                    {(isFirstRecordingCompleted && !showAnimation) && 
                        <SpeakingLabPlaybackOptions
                            systemPronunceButtonClick={() => speakSentence(
                                exerciseData?.exercise?.sentence, 
                                process.env.REACT_APP_LANG_EN_CODE, 
                                exerciseData?.exercise?.sentenceId
                            )}
                            memberAudioUrl={speechStudioResult?.audioUrl}
                            audioKey={audioKey}
                        />
                    }
                    <div className="flex gap-2 justify-center items-center mb-2">
                        <div className="relative group">
                            <button
                                type="button"
                                className={classNames("flex items-center justify-center h-14 w-14 bg-nav-button-bg rounded-full", {
                                    "opacity-70 pointer-events-none" : !isSpeakingComplete || showAnimation,
                                    "hover:bg-nav-button-bg/70" : isSpeakingComplete && !showAnimation
                                })}
                                disabled={!isSpeakingComplete || showAnimation}
                                onClick={() => {
                                    speakSentence(ttsInfo.sentence, ttsInfo.language, ttsInfo.textId);
                                    analytics.useAnalytics(EventLogs.SPEAKING_LAB_VOICE_AGAIN_CLICKED);
                                }}
                            >
                                <span className="material-symbols-outlined text-base-text text-[26px]">replay</span>
                            </button>
                            {(isSpeakingComplete && !showAnimation) &&
                                <div className="tooltip-top -top-[38px]">
                                    {strings.speaking_lesson.speaking_lab.voice_again}
                                </div>
                            }
                        </div>
                        {showPronunciationExercise && 
                            <div className="relative group">
                                <button
                                    type="button"
                                    className={classNames("flex items-center justify-center h-14 w-14 bg-nav-button-bg rounded-full hover:bg-nav-button-bg/70", {
                                        "opacity-70 pointer-events-none" : speechStudioLoading || !isSpeakingComplete,
                                    })}
                                    disabled={speechStudioLoading || !isSpeakingComplete}
                                    onClick={() => handlePronunciation(exerciseData.exercise?.sentence)}
                                >
                                    <span className="material-symbols-outlined text-base-text text-[28px]">mic</span>
                                    {showAnimation ? (
                                        <div className="absolute inset-0 rounded-full overflow-hidden  animate-overlay">
                                            <Lottie 
                                                animationData={defaultOptions.animationData} 
                                                loop={true}
                                                autoplay={true}
                                                style={{ height: 56, width: 56 }}
                                            />
                                        </div>
                                    ) : null}
                                </button>
                                {isSpeakingComplete && 
                                    <div className="tooltip-top -top-[38px]">
                                        {strings.video_practice.word_card.word_pronunciation}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>

            {speechStudioLoading && <Loading text={strings.speaking_lesson.speaking_lab.results_loading} />}

            {speechStudioResult && !speechStudioLoading &&
                <div className="flex items-center justify-center">
                    <ScoreCard result={speechStudioResult} classnames="mt-5 max-w-[500px]"/>
                </div>
            }

            {(isDraggedSentenceCorrect === null && !showPronunciationExercise) && 
                <div className='btn-outer-div my-5'>
                    <button
                        type="button"
                        className="button primary-button max-w-[330px]"
                        onClick={() => {
                            checkSentence();
                            analytics.useAnalytics(EventLogs.SPEAKING_LAB_CREATE_SENTENCE_CHECK_CLICK);
                        }}
                    >
                        {strings.speaking_lesson.quiz.control}
                    </button>
                </div>
            }

            <>
                {isMobile && <div className="h-[180px]"></div>}
                {renderResult()}
            </>
            
        </>
    );
};

export default SpeakingLabCreateSentence;