import classNames from "classnames";
import { AnimatePresence, motion } from 'framer-motion';
import Lottie from "lottie-react";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";
import deleteAccount from '../../../../assets/animation/delete_account.json';
import { Trash } from "../../../../assets/svg";
import { ModalOneButton, ModalOverlay, ModalTwoButtons } from "../../../../components";
import { EventLogs } from "../../../../components/Constants";
import useAnalytics from "../../../../hooks/useAnalytics";
import { useLocalization } from "../../../../hooks/useLocalization";
import useWindowSize from "../../../../hooks/useWindowSize";
import { SendTicketService } from "../../../../services/Support/SupportService";
import { closeModal, openModal } from "../../../../utils";
import { url } from "../../../../routes/utility";

const DeleteAccount = ({ memberId, token }) => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const navigate = useNavigate();

    const windowSize = useWindowSize();
    const isMobile = windowSize?.width < 640;

    const modalOverlayRef = useRef();
    const modalDeleteAccountRef = useRef();
    const modalSecondDeleteAccountRef = useRef();
    const modalThirdDeleteAccountRef = useRef();

    const [openBottomSheet, setOpenBottomSheet] = useState(false);
    const [showWebCard, setShowWebCard] = useState(false);

    const handleDeleteAccount = async () => {
        const msg = "Hesabımı silmek istiyorum";

        const result = await SendTicketService(memberId, msg, token);

        if (result.statusCode === 200) {
            analytics.useAnalytics(EventLogs.ACCOUNT_DELETE_REQUEST_SEND);
        } else {
            if (result.message) {
                analytics.useAnalytics("Error_Occured", {
                    error: `${result.message}`,
                });
            } else {
                analytics.useAnalytics("Error_Occured", {
                    error: `An unexpected error occurred.`,
                });
            }
        }
    };

    const containerVariants = {
        initial: { opacity: 0, y: 50 },
        animate: { opacity: 1, y: 0, transition: { duration: 0.3, ease: 'easeOut' } },
        exit: { opacity: 0, y: 50, transition: { duration: 0.1, ease: 'easeIn' } }
    };

    const overlayVariants = {
        initial: { opacity: 0 },
        animate: { opacity: 1, transition: { duration: 0.3 } },
        exit: { opacity: 0, transition: { duration: 0.3 } }
    };

    const DeleteAccountContent = () => (
        <div 
            className={classNames("flex flex-col h-full", {
                "mb-16": isMobile
            })}
        >
            <div className="p-6 flex-1">
                <div className="flex justify-center items-center">
                    <Lottie
                        animationData={deleteAccount}
                        loop
                        autoplay
                        style={{ height: '256px', width: '256px' }}
                    />
                </div>

                <p className="font-bold text-[18px] text-center">
                    {strings.support.delete_account.title}
                </p>

                <p className="text-center my-6 max-w-[450px] mx-auto">
                    {strings.support.delete_account.description}
                </p>

                <button
                    type="button"
                    className="button w-full primary-button mb-2 focus:!bg-primary max-w-[330px] mx-auto"
                    onClick={() => navigate(url("support.sendticket"))}
                >
                    {strings.support.delete_account.write_message_button}
                </button>
            </div>

            {/* Hesap Sil butonu - En altta sabit */}
            <div className="border-t border-gray-200">
                <button
                    type="button"
                    className="flex items-center justify-center gap-0.5 w-full py-4 hover:bg-gray-50 transition-colors duration-200"
                    onClick={() => {
                        if (isMobile) setOpenBottomSheet(false);
                        else setShowWebCard(false);

                        setTimeout(() => {
                            openModal(modalOverlayRef, modalDeleteAccountRef);    
                        }, 300); 
                    }}
                >
                    <img
                        src={Trash ?? ""}
                        width="20"
                        height="20"
                        alt="trash"
                    />
                    <p className="font-bold text-light-gray text-[14px]">
                        {strings.support.main_page.delete_account}
                    </p>
                </button>
            </div>
        </div>
    );

    return (
        <>
            <button
                type="button"
                className="flex gap-0.5"
                onClick={() => {
                    if (isMobile) setOpenBottomSheet(true);
                    else setShowWebCard(true);
                }}
            >
                <img
                    src={Trash ?? ""}
                    width="20"
                    height="20"
                    alt="trash"
                />
                <p className="font-bold text-light-gray text-[14px]">
                    {strings.support.main_page.delete_account}
                </p>
            </button>

            {/* Mobile Bottom Sheet */}
            {isMobile &&
                <BottomSheet
                    open={openBottomSheet}
                    onDismiss={() => setOpenBottomSheet(false)}
                    className="mobile-view"
                >
                    <DeleteAccountContent />
                </BottomSheet>
            }

            {/* Web Card */}
            <AnimatePresence>
                {!isMobile && showWebCard && (
                    <>
                        <motion.div 
                            className="fixed inset-0 bg-[#333333]/70 z-40"
                            onClick={() => setShowWebCard(false)}
                            variants={overlayVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                        />
                        <motion.div
                            className="fixed inset-0 flex items-center justify-center z-50 px-4"
                            variants={containerVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                        >
                            <div className="bg-white rounded-lg shadow-popup w-[570px] h-[570px] relative overflow-hidden">
                                <button 
                                    type="button"
                                    className="flex justify-center items-center absolute right-3 top-3 w-6 h-6 opacity-40 hover:opacity-100 before:absolute before:bg-[#333333] before:content-[''] before:h-6 before:w-[2px] before:rounded-full before:rotate-45 after:absolute after:content-[''] after:bg-[#333333] after:rounded-full after:h-6 after:w-[2px] after:-rotate-45"
                                    onClick={() => setShowWebCard(false)}
                                />
                                <DeleteAccountContent />
                            </div>
                        </motion.div>
                    </>
                )}
            </AnimatePresence>

            <ModalOverlay ref={modalOverlayRef}/>
            <ModalTwoButtons
                ref={modalDeleteAccountRef}
                overlayRef={modalOverlayRef}
                title={strings.modal.transaction_information}
                message={strings.support.delete_account.modal_delete_account_first_content}
                buttonText1={strings.modal.approve}
                buttonClick1={() => {
                    closeModal(modalOverlayRef, modalDeleteAccountRef);
                    openModal(modalOverlayRef, modalSecondDeleteAccountRef);
                }}
                buttonText2={strings.modal.dont_approve}
                buttonClick2={() => closeModal(modalOverlayRef, modalDeleteAccountRef)}
            />
            <ModalTwoButtons
                ref={modalSecondDeleteAccountRef}
                overlayRef={modalOverlayRef}
                title={strings.modal.transaction_information}
                message={strings.support.delete_account.modal_delete_account_second_content}
                buttonText1={strings.modal.approve}
                buttonClick1={() => {
                    handleDeleteAccount();
                    closeModal(modalOverlayRef, modalSecondDeleteAccountRef);
                    openModal(modalOverlayRef, modalThirdDeleteAccountRef);
                }}
                buttonText2={strings.modal.dont_approve}
                buttonClick2={() => closeModal(modalOverlayRef, modalSecondDeleteAccountRef)}
            />
            <ModalOneButton
                ref={modalThirdDeleteAccountRef}
                overlayRef={modalOverlayRef}
                title={strings.modal.information_message}
                message={strings.support.delete_account.modal_delete_account_third_content}
                buttonText={strings.modal.okey}
                buttonClick={() => closeModal(modalOverlayRef, modalThirdDeleteAccountRef)}
            />
        </>
    );
};

export default DeleteAccount;